import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  ImageSection,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import banner_image from "../../../assests/banner.jpg";

const PeerPolicy = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        <SectionWrapper background="#e4284a" padding="20px">
          <Title color="#fff" textAlign="center" alignItems="center">
            ABOUT IJAMA
          </Title>
          <Subtitles color="#fff" font="15px" className="mt-3">
            The IJAMA is committed to sustain the highest standards of
            publication ethics. Authors should submit their works to IJAMA for
            publication as an original article. IJAMA encourages integrity in
            research, writing, and peer review by presenting the gold-standard
            for editorial processes across the scholarly disciplines.​
            <br />
            <br />
            The editors should strive to meet the needs of readers and authors;
            strive to constantly improve their journal; have processes in place
            to assure the quality of the material they publish; champion freedom
            of expression; maintain the integrity of the academic record;
            preclude business needs from compromising intellectual and ethical
            standards; always be willing to publish corrections, clarifications,
            retractions and apologies when needed; always be willing to publish
            corrections, clarifications, retractions and apologies when needed.
            Situations that may lead to conflicts of interest should be avoided.
            <br />
            <br />
          </Subtitles>{" "}
          <Title color="#fff" textAlign="start" alignItems="center" font="15px">
            <ul>
              <li>Submit Your article: editorijama@gmail.com</li>
              <li>Frequency: 2 issues per year.</li>
              <li>Subject : Multidisciplinary</li>
              <li>ISSN: xxxx-xxxx</li>
              <li>Published By: ______________</li>
            </ul>
          </Title>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default PeerPolicy;
