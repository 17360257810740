import styled from "styled-components";

export const FooterWrapper = styled.div`
  background: #001b69;
  width: 100%;
  padding: 50px 50px 15px 50px;
`;

export const FooterLinkTest = styled.div`
  padding: 5px 0;
  color: #fff;
  font-size: 14px;
  .quick {
    cursor: pointer;
    font-size: 14px;
  }
`;

export const CopyFooter = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 15px 0 0;
  color: #fff;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  i {
    margin: 0 10px;
    font-size: 23px;
    cursor: pointer;
  }
`;
