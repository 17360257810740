import styled from "styled-components";
// import home_1 from "../../assests/11031854.jpg";
import home_2 from "../../assests/about/col_1.jpg";
import { Button, Card, Offcanvas } from "react-bootstrap";

// export const NavSectionWrapper = styled.div`
//   background-image: url(${home_1});
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center 100%;
//   height: 100vh;
//   position: relative;
// `;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100% !important;
  min-width: 100% !important;
  display: flex;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageTextContainer = styled.div`
  width: 100%;
  background-color: #c70f2c;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const AboutCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #c70f2c;
  min-height: 336px !important;
  min-width: 336px !important;
  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
    min-height: 100% !important;
    min-width: 100% !important;
  }
  @media (max-width: 450px) {
    width: 100%;
    height: 100%;
    min-height: 100% !important;
    min-width: 100% !important;
  }
`;

export const HomeImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  margin: 0 auto;
`;
export const CarouselImages = styled.img`
  object-fit: contain;
  width: 500px;
  height: auto;
`;

// ----------------------------------------------NEWS_CARD--------------------------------------------

export const NewsImageSection = styled.div`
  background-image: url(${home_2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  object-fit: contain;
  background-color: rgba(244, 244, 244, 0.91);
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
`;

export const NewsCardWrapper = styled(Card)`
  border-radius: 0;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 24%;
  padding: 0 10px 0;
  background-color: #fff;
  perspective: 500px;
  cursor: pointer;
  @media (max-width: 950px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    width: 40%;
  }
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    width: 70%;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const CardWrapper = styled.div<{ background?: string }>`
  position: relative;
  background: transparent;
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  @media (max-width: 850px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 45%;
    margin: 0 auto;
  }
  @media (max-width: 650px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 65%;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
`;

export const JournyWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
  @media (max-width: 850px) {
    flex-wrap: wrap;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
  }
`;

export const StyledButton2 = styled(Button)`
  position: absolute;
  width: 100%;
  bottom: 0;
  border: none;
  border-radius: 0;
  height: 60px;
  background: #c70f2c;
  font-weight: 500;
`;

//---------------------------------------------------Offcanvas--------------------------------------------

export const CanvaContianer = styled.div`
  position: relative;
  z-index: 1111111;
  @media (min-width: 850px) {
    display: none;
  }
`;

export const Offcanva = styled(Offcanvas)``;
