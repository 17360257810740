import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  ImageSection,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import banner_image from "../../../assests/banner.jpg";

const PublicEthics = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        <SectionWrapper background="#e4284a" padding="20px">
          <Title color="#fff" textAlign="center" alignItems="center">
            PUBLICATION ETHICS
          </Title>
          <br />
          <Subtitles color="#fff" font="15px" className="mt-3">
            IJAMA offer a double-blind peer review option where both reviewers
            and authors remain anonymous throughout the review process. All
            research articles are reviewed by at least two suitably qualified
            experts. Editors of IJAMA will decide promptly whether to accept,
            reject, or request revisions of referred papers based on the
            reviews. All publication decisions are made by the journals’
            Editors-in-Chief on the basis of the reviews provided. Editors will
            have the option of seeking additional reviews when needed. Authors
            will be advised when Editors decide further review is needed. <br />
            <br /> We send reviewers’ comments along with the decision letter to
            all reviewers of that manuscript. If reviewers have identified
            themselves, this information will be passed on to other reviewers.
            We aim to make the review process as efficient as possible, whilst
            maintaining a high quality of reviewer feedback.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            POLICY ON RETRACTION OF ARTICLES
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            TiThe Journal follows the COPE guidelines for Retraction of
            articles: It can be found at following link:
            http://publicationethics.org/files/retraction%20guidelines.pdf
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            PLAGIARISM
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            Plagiarism is copying another person's text or ideas and passing the
            copied material as your own work. Plagiarism is considered fraud and
            has potentially harsh consequences. The IJAMA will judge any case of
            plagiarism on its own merits. If plagiarism is detected, either by
            the editors, reviewers at any stage before publication of a
            manuscript- before or after acceptance, we will ask the author(s),
            to rewrite the text. If atleast 25% of the original submission is
            plagiarized the article may be rejected. Many online softwares are
            now available to detect possible plagiarism which an author may use
            before submitting a manuscript to IJAMA.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            HELP
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            If you have questions or concerns about the manuscript you are
            reviewing, or if you need assistance submitting the review, please
            email us.
          </Subtitles>{" "}
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default PublicEthics;
