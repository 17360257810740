import React, { ReactElement } from "react";
import { PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";

const CodeofConduct = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Code of Conduct
          </Title>
          <br />
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Respect for Tradition and Lineage:
            </Title>
            Practitioners of Kalari Martial Arts must honor and respect the
            ancient tradition and lineage of this martial art form. They should
            show reverence towards their masters, teachers, and the historical
            roots of Kalari.
            <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Discipline and Dedication:
            </Title>
            Kalari Martial Arts require unwavering discipline and dedication
            from its practitioners. Students must commit themselves
            wholeheartedly to their training, attending classes regularly, and
            striving for continuous improvement.
            <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Safety and Well-being:
            </Title>
            The safety and well-being of all participants are paramount in
            Kalari Martial Arts. Practitioners must prioritize safety during
            training sessions and competitions, following proper techniques and
            guidelines to prevent injuries. <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Humility and Modesty:
            </Title>
            Practitioners of Kalari Martial Arts should approach their training
            with humility and modesty. They must acknowledge their strengths and
            weaknesses, showing respect towards fellow practitioners regardless
            of rank or experience.
            <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Non-Violence and Self-Control:
            </Title>
            While Kalari Martial Arts involve combat techniques, practitioners
            must understand the principles of non-violence and self-control.
            They should only use their skills for self-defense and exercise
            restraint in confrontational situations. <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Sportsmanship and Fair Play:
            </Title>
            Integrity and sportsmanship are core values in Kalari Martial Arts.
            Practitioners must compete with honor, fairness, and respect for
            their opponents, adhering to the rules and regulations of the art.
            <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Cultural Sensitivity and Inclusivity:
            </Title>
            Kalari Martial Arts embrace diversity and inclusivity. Practitioners
            should show respect for different cultures, backgrounds, and
            perspectives within the Kalari community and beyond, fostering a
            welcoming and inclusive environment. <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Continuous Learning and Improvement:
            </Title>
            Learning is a lifelong journey in Kalari Martial Arts. Practitioners
            should approach each training session with a mindset of continuous
            learning and improvement, seeking guidance from their instructors
            and striving to refine their skills. <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Community Engagement and Service:
            </Title>
            Practitioners of Kalari Martial Arts are encouraged to engage with
            their communities and contribute positively to society. They should
            embody the values of discipline, respect, and compassion both within
            and outside the training arena.
            <br /> <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Adherence to Ethical Standards:
            </Title>
            Practitioners must uphold ethical standards in all aspects of their
            practice. This includes honesty, integrity, and respect for the
            principles of Kalari Martial Arts both on and off the training mat.
            <br />
            <br />
            By adhering to this Code of Conduct, practitioners of Kalari Martial
            Arts uphold the traditions, values, and ethos of this ancient
            martial art form, fostering a culture of respect, integrity, and
            excellence within the community.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
    </PageWrapper>
  );
};

export default CodeofConduct;
