import menus from "./menu";
import { Menus } from "./types";

const determineDropdown = (menuName: string): Array<Menus> => {
  const { about, journal, news, champoins, gov } = menus;
  switch (menuName) {
    case "about":
      return about as Array<Menus>;
    case "journal":
      return journal as Array<Menus>;
    case "news":
      return news as Array<Menus>;
    case "gov":
      return gov as Array<Menus>;
    case "chap":
      return champoins as Array<Menus>;
    default:
      return [];
  }
};

export default determineDropdown;
