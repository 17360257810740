import { useState, useEffect } from "react";
import {
  DropdownMenuList,
  DropdownWrapper,
  DropdownAnchor,
} from "./subcomponents";
import { useNavigate } from "react-router";
import Aos from "aos"; 
import determineDropdown from "./determineDropdown";

const Dropdown = ({ name }: any) => {
  const [click, setClick] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []); 

  return (
    <>
      <DropdownWrapper
        onClick={() => {
          setClick(!click);
        }}
        data-aos="fade-up"
        data-aos-duration="300"
      >
        {determineDropdown(name)?.map((item, index) => {
          return (
            <DropdownMenuList key={index}>
              <DropdownAnchor
                onClick={() => {
                  navigate(item?.to);
                }}
              >
                {item?.title}
              </DropdownAnchor>
            </DropdownMenuList>
          );
        })}
      </DropdownWrapper>
    </>
  );
};

export default Dropdown;
