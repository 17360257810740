import React, { ReactElement } from "react";
import { Footer, PageWrapper, StyledButton } from "../../../components";
import {
  Columnwrapper,
  Rowwrapper,
  SectionContainer,
  SectionWrapper,
  TitleWrapper,
  Subtitles,
} from "../../subcomponents";
import { FormInput } from "./subcomponents";

const Contactus = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer padding="0">
        <SectionWrapper width="100%">
          <Rowwrapper margin="0">
            <Columnwrapper style={{ width: "50%", margin: "0 auto" }}>
              <div style={{ width: "60%" }}>
                <TitleWrapper
                  textAlign="start"
                  alignItems="start"
                  paddingLeft="40px"
                >
                  <Subtitles font="1.25rem" color="#C70F2C" fontWeight={600}>
                    CONTACT US
                  </Subtitles>

                  <div style={{ width: "95%", marginTop: "10px" }}>
                    <FormInput placeholder={"Full Name"} />
                    <FormInput placeholder={"Email Address"} />
                    <FormInput placeholder={"Phone No."} />
                    <FormInput
                      placeholder={"Message"}
                      style={{ height: "100px" }}
                    />
                  </div>
                  <StyledButton style={{ margin: "0 auto" }}>
                    Submit
                  </StyledButton>
                </TitleWrapper>
              </div>
            </Columnwrapper>
          </Rowwrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Contactus;
