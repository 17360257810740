import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  PartnerImages,
  SectionContainer,
  SectionWrapper,
  Partnerss,
} from "../../subcomponents";
import img_1 from "../../../assests/gallery/img_1.webp";
import img_2 from "../../../assests/gallery/img_2.webp";
import img_4 from "../../../assests/gallery/img_3.webp";
import img_3 from "../../../assests/gallery/img_4.webp";
import img_5 from "../../../assests/gallery/img_5.webp";
import img_6 from "../../../assests/gallery/img_6.webp";
import img_7 from "../../../assests/gallery/img_7.webp";
import img_8 from "../../../assests/gallery/img_8.webp";

const Gallery = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper background="#C70F2C" padding="20px">
          {" "}
          <PartnerImages>
            <Partnerss src={img_2} />
            <Partnerss src={img_3} />
            <Partnerss src={img_4} />
            <Partnerss src={img_5} />
            <Partnerss src={img_6} />
            <Partnerss src={img_7} />
            <Partnerss src={img_8} />
            <Partnerss src={img_1} />
          </PartnerImages>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Gallery;
