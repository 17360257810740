import React, { ReactElement } from "react";
import { FooterWrapper, FooterLinkTest, CopyFooter } from "./subcomponents";
import {
  Columnwrapper,
  Rowwrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../pages/subcomponents";
import { useNavigate } from "react-router";

const Footer = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <FooterWrapper>
      <Rowwrapper>
        <Columnwrapper lg={4} justifyContent="">
          <TitleWrapper alignItems="start" textAlign="start">
            <Title font="1rem" color="#fff">
              CONATCT
            </Title>
            <Subtitles>
              <FooterLinkTest> contactus@britishkalari.org</FooterLinkTest>
              <FooterLinkTest> +44 7584 329323</FooterLinkTest>
              <FooterLinkTest>
                14 Blackheath, Colchester, CO20AA. United Kingdom
              </FooterLinkTest>
            </Subtitles>
          </TitleWrapper>
        </Columnwrapper>
        <Columnwrapper lg={4} justifyContent="none">
          <TitleWrapper alignItems="start" textAlign="start">
            <Title font="1rem" color="#fff">
              QUICK LINKS
            </Title>
            <Subtitles font="15px">
              <FooterLinkTest
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                <p className="quick">Privacy Policy</p>
              </FooterLinkTest>
            </Subtitles>
          </TitleWrapper>
        </Columnwrapper>
      </Rowwrapper>
      <CopyFooter>
        <div>Copyright 2024 | British Kalari | All Rights Reserved </div>
        <div>
          {" "}
          <i
            className="bx bxl-facebook-circle"
            onClick={() => {
              window.open(
                "https://www.facebook.com/profile.php?id=61555974796395"
              );
            }}
          ></i>
          <i className="bx bxl-linkedin"></i>
          <i className="bx bxl-instagram-alt"></i>
          <i className="bx bxl-twitter"></i>
          <i
            className="bx bxl-youtube"
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UCaNTcW6ZmxU1CwfCc8SNa3A"
              );
            }}
          ></i>
        </div>
      </CopyFooter>
    </FooterWrapper>
  );
};

export default Footer;
