import React, { ReactElement } from "react";
import banner_image from "../../assests/banner.jpg";
import { Accordian, Footer, PageWrapper } from "../../components";
import {
  ImageSection,
  SectionContainer,
  SectionWrapper,
  Title,
} from "../subcomponents";
import { senoirsfaq } from "./const";

const FAQ = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}></ImageSection>
      <SectionContainer>
        <SectionWrapper background="#e4284a" padding="20px">
          {" "}
          <Title color="#fff" textAlign="center" alignItems="center">
            FREQUENTLY ASKED QUESTIONS
          </Title>
          <br />
          <Accordian data={senoirsfaq} />
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default FAQ;
