import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Title,
  Subtitles,
} from "../../subcomponents";
import { Footer, PageWrapper } from "../../../components";

const EqualityDiversity = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Equality & Diversity
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            British Kalari is dedicated to fostering an environment where
            diversity is embraced, and all individuals are treated with dignity
            and respect. We are committed to promoting equality of opportunity
            and to ensuring that discrimination, harassment, or prejudice of any
            kind is not tolerated within our community. Our aim is to create an
            inclusive space where everyone, regardless of their background or
            identity, feels valued and able to participate fully in the practice
            and promotion of Kalari Martial Art.
            <br />
            <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Our Commitments:
            </Title>
            <br />
            <b>Our Commitments:</b>
            <br />
            We are dedicated to providing equal opportunities for all
            individuals to engage in the practice and promotion of Kalari
            Martial Art, regardless of their race, ethnicity, nationality,
            gender, age, sexual orientation, disability, religion, or any other
            characteristic. <br />
            Discrimination in any form, including direct or indirect
            discrimination, harassment, victimization, or bullying, will not be
            tolerated within our organization.
            <br />
            <br />
            <b>Diversity and Inclusion:</b>
            <br />
            We celebrate the diversity of our community and strive to create an
            inclusive environment where all individuals feel welcomed,
            respected, and valued. We actively encourage participation from
            individuals of diverse backgrounds, including those who may be
            underrepresented or marginalized.
            <br />
            <br />
            <b>Diversity and Inclusion:</b>
            <br />
            We are committed to making British Kalari accessible to individuals
            with disabilities by providing reasonable accommodations and support
            as needed. <br /> Our training facilities and programs are designed
            to be inclusive and accessible to all participants, regardless of
            their physical or cognitive abilities.
            <br />
            <br />
            <b>Training and Awareness:</b>
            <br />
            We provide training and awareness programs for our instructors,
            staff members, and volunteers to ensure they are equipped with the
            knowledge and skills to promote equality and diversity within our
            community. <br />
            We encourage ongoing learning and dialogue around diversity and
            inclusion to foster a culture of understanding and acceptance
            <br />
            <br />
            <b>Zero Tolerance Policy:</b>
            <br />
            We maintain a zero-tolerance policy towards discrimination,
            harassment, bullying, or any other form of inappropriate behavior
            within our organization. <br /> Any reports or complaints regarding
            discrimination or harassment will be taken seriously, investigated
            promptly, and addressed in accordance with our policies and
            procedures.
            <br />
            <br />
            <b>Promotion of Diversity:</b>
            <br />
            We actively promote diversity within our organization through our
            recruitment practices, outreach efforts, and community engagement
            initiatives. <br />
            We strive to create opportunities for individuals from diverse
            backgrounds to participate in leadership roles, events, and projects
            within British Kalari.
            <br />
            <br />
            <b>Community Engagement:</b>
            <br />
            We engage with and contribute to the wider community by promoting
            diversity and inclusion through outreach programs, partnerships, and
            collaborations with local organizations and initiatives. <br /> We
            seek to be a positive force for social change by advocating for
            equality and justice both within our organization and in society at
            large.
            <br />
            <br />
            <b>Implementation and Review:</b>
            <br />
            This Equality and Diversity Policy will be communicated to all
            members, instructors, staff, volunteers, and stakeholders of British
            Kalari. <br /> We are committed to regularly reviewing and
            evaluating our policies, practices, and procedures to ensure they
            are aligned with our commitment to equality and diversity and to
            make any necessary improvements. <br /> At British Kalari, we
            believe that embracing diversity and fostering inclusion is
            fundamental to the practice and promotion of Kalari Martial Art. We
            are dedicated to creating a welcoming and supportive community where
            everyone can thrive and contribute to the rich tapestry of our
            organization.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default EqualityDiversity;
