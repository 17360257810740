import React, { ReactElement, useEffect } from "react";
import { Footer, PageWrapper } from "../../components";
import {
  AboutCardWrapper,
  CardContainer,
  Image,
  ImageTextContainer,
  ImageWrapper,
  JournyWrapper,
  NewsImageSection,
} from "./subcomponents";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../subcomponents";
import { Cards, NewsCard } from "./helpers";
//images
import coach from "../../assests/home_journy/Coach.jpg";
import student from "../../assests/home_journy/student.jpg";
import academy from "../../assests/home_journy/academy.jpg";
import col_1 from "../../assests/about/col_1.jpg";
import col_2 from "../../assests/about/col_3.jpg";
import col_3 from "../../assests/about/11031850.jpg";
import col_4 from "../../assests/about/11031868.jpg";
import news_01 from "../../assests/news/news_01.jpg";
import news_02 from "../../assests/news/news_02.jpg";
import news_03 from "../../assests/news/news_03.jpg";
import news_04 from "../../assests/news/news_04.jpg";
import Aos from "aos";
import videoBg from "../../assests/video/v_1.mp4";

const Home = (): ReactElement => {
  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);

  return (
    <>
      <PageWrapper>
        <div className="main">
          <div className="overlay"></div>
          <video src={videoBg} autoPlay loop muted />
          <div className="content">
            <h1>Welcome</h1>
            <p>To my site.</p>
          </div>
        </div>

        <NewsImageSection>
          <SectionContainer
            padding="30px 0"
            background="rgba(244,244,244,0.91)"
          >
            <SectionWrapper>
              <Title font="1.2rem" color="black">
                Latest News{" "}
                <div>
                  <div className="light-line"></div>{" "}
                  <div className="light-line mt-1"></div>
                </div>
              </Title>
              <CardContainer className="mt-3">
                <NewsCard
                  image={news_01}
                  title="Agastya and Dhanurveda: The sage Agastya, a revered figure in Indian mythology, is often associated with the development of martial arts, including Kalari. Dhanurveda, the ancient Indian science of archery and warfare, is believed to have influenced the early stages of Kalari training."
                  date={new Date()}
                />
                <NewsCard
                  image={news_02}
                  title="Sangam Era Battles: The Sangam period was marked by frequent wars and conflicts among various Tamil dynasties. Martial skills, including those taught in Kalari, would have been of practical importance for warriors engaged in these battles. The effectiveness of these combat techniques likely contributed to the continued practice and development of Kalari."
                  date={new Date()}
                />
                <NewsCard
                  image={news_03}
                  title="Rituals and Festivals: Kalari was not only a practical skill for self-defense and warfare but also an integral part of the cultural fabric. It was often associated with religious rituals, festivals, and performances. The integration of Kalari into various aspects of daily life helped to preserve and propagate the martial art."
                  date={new Date()}
                />
                <NewsCard
                  image={news_04}
                  title="During the Sangam period, martial arts like Kalari were likely transmitted through a traditional Gurukul system, where students lived with their gurus (teachers) and underwent rigorous training. This method of transmission helped preserve the authenticity and depth of knowledge associated with Kalari. daily life helped to preserve and propagate the martial art."
                  date={new Date()}
                />
              </CardContainer>
            </SectionWrapper>
          </SectionContainer>
        </NewsImageSection>
        <SectionContainer background="#fff" padding="30px 0">
          <SectionWrapper>
            <TitleWrapper>
              <Title font="25px">Begin My Journey – I would like to…</Title>
            </TitleWrapper>
            <JournyWrapper>
              <Cards image={coach} buttonName="Register as Aasan" />
              <Cards image={student} buttonName="Register as Students" />
              <Cards image={academy} buttonName="Register as Academy" />
            </JournyWrapper>
          </SectionWrapper>
        </SectionContainer>

        <SectionContainer background="#ececec" padding="50px 0 0">
          <SectionWrapper width="100%">
            <TitleWrapper>
              <Title color="#2e2b36" font="1.9rem" lineHeight="44px">
                ABOUT KALARI
              </Title>
            </TitleWrapper>
            <ImageTextContainer>
              <AboutCardWrapper data-aos="fade-up">
                {" "}
                <ImageWrapper>
                  <Image src={col_1} alt="kalari_image" />
                </ImageWrapper>
              </AboutCardWrapper>
              <AboutCardWrapper data-aos="fade-down">
                {" "}
                <TitleWrapper margin="0" padding="70px 30px">
                  <Title font="1rem" color="#fff" lineHeight="40px">
                    Holistic Fitness
                  </Title>
                  <Subtitles color="#fff" fontWeight={400} font=".900rem">
                    Engage in a dynamic workout that enhances flexibility,
                    strength, and endurance. British Kalari's fluid movements
                    promote overall physical well-being.
                  </Subtitles>
                </TitleWrapper>
              </AboutCardWrapper>
              <AboutCardWrapper data-aos="fade-up">
                {" "}
                <ImageWrapper>
                  <Image src={col_2} alt="kalari_image" />
                </ImageWrapper>
              </AboutCardWrapper>
              <AboutCardWrapper data-aos="fade-down">
                {" "}
                <TitleWrapper margin="0" padding="70px 30px">
                  <Title font="1rem" color="#fff" lineHeight="40px">
                    Self-Defense Mastery
                  </Title>
                  <Subtitles color="#fff" fontWeight={400} font=".900rem">
                    Equip yourself with practical self-defense techniques that
                    are both effective and empowering. Learn how to protect
                    yourself and loved ones in any situation.
                  </Subtitles>
                </TitleWrapper>
              </AboutCardWrapper>
            </ImageTextContainer>{" "}
            <ImageTextContainer>
              <AboutCardWrapper data-aos="fade-down">
                {" "}
                <TitleWrapper margin="0" padding="70px 30px">
                  <Title font="1rem" color="#fff" lineHeight="40px">
                    Mental Focus and Clarity
                  </Title>
                  <Subtitles color="#fff" fontWeight={400} font=".900rem">
                    Experience heightened mental awareness through the
                    meditative aspects of British Kalari. Sharpen your
                    concentration and cultivate a calm, focused mind.!
                  </Subtitles>
                </TitleWrapper>
              </AboutCardWrapper>
              <AboutCardWrapper data-aos="fade-up">
                {" "}
                <ImageWrapper>
                  <Image src={col_3} alt="kalari_image" />
                </ImageWrapper>
              </AboutCardWrapper>
              <AboutCardWrapper data-aos="fade-down">
                {" "}
                <TitleWrapper margin="0" padding="70px 30px">
                  <Title font="1rem" color="#fff" lineHeight="40px">
                    Cultural Heritage
                  </Title>
                  <Subtitles color="#fff" fontWeight={400} font=".900rem">
                    Immerse yourself in the rich cultural heritage of India as
                    you explore the history and philosophy behind Kalari
                    Adimurai. Connect with a community of like-minded
                    individuals passionate about preserving this ancient art.
                  </Subtitles>
                </TitleWrapper>
              </AboutCardWrapper>
              <AboutCardWrapper data-aos="fade-up">
                {" "}
                <ImageWrapper>
                  <Image src={col_4} alt="kalari_image" />
                </ImageWrapper>
              </AboutCardWrapper>
            </ImageTextContainer>
          </SectionWrapper>
        </SectionContainer>
      </PageWrapper>
      <Footer />
    </>
  );
};

export default Home;
