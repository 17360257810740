import React, { useState, useEffect } from "react";
import {
  AboutIjama,
  AuthorGuidliness,
  BoardOfDirectors,
  Champions,
  CodeofConduct,
  Contactus,
  EqualityDiversity,
  FAQ,
  GDPR,
  Gallery,
  Health,
  History,
  Home,
  HowWeDo,
  JournalHome,
  LeadersDetails,
  Memoradum,
  Partners,
  PeerPolicy,
  PeoplePlane,
  Policies,
  Privacy,
  Programs,
  PublicEthics,
  Reports,
  StrategicPlane,
  WhatweDo,
  WhoWeAre,
} from "./pages";
import { createGlobalStyle } from "styled-components";
import { Route, Routes } from "react-router";
import { Header } from "./components";
import preLoader from "./assests/logo.png";
import { PreLoaderIcons } from "./pages/subcomponents";

export const GlobalStyles = createGlobalStyle`
  body{
    font-family: 'Montserrat', sans-serif;
  }
  .line{
    width: 100px; 
    height: 2px;
    background-color: black; 
  }
  .light-line{
    width: auto; 
    height: 1px;
    background-color: grey; 
    margin-left: 10px;
    margin-top: 10px;
  }
  .calendar{
    font-size: 30px;
    position: relative;
    left: 10px;
  }
  table { 
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
} 
.main {
  width: 100%;
  height: 100vh;
  @media (max-width:450px) {
    
  width: 100%;
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
}
 
`;

function App() {
  const [isLazyLoading, setIsLazyLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLazyLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      {" "}
      {isLazyLoading ? (
        <div
          style={{
            width: "30%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <PreLoaderIcons src={preLoader} alt="pre_loader" />
        </div>
      ) : (
        <>
          <GlobalStyles />
          <Header />
          <div style={{ position: "relative", top: "130px" }}>
            <Routes>
              <Route path={"/"} element={<Home />} />
              <Route path={"/contact-us"} element={<Contactus />} />
              <Route path={"/what-we-do"} element={<WhatweDo />} />
              <Route path={"/history"} element={<History />} />
              <Route path={"/who-we-are"} element={<WhoWeAre />} />
              <Route path={"/how-we-do"} element={<HowWeDo />} />
              <Route path={"/journal-home"} element={<JournalHome />} />
              <Route path={"/about-ijama"} element={<AboutIjama />} />
              <Route path={"/peer-policy"} element={<PeerPolicy />} />
              <Route
                path={"/author-guidliness"}
                element={<AuthorGuidliness />}
              />
              <Route path={"/public-ethics"} element={<PublicEthics />} />
              <Route path={"/partners"} element={<Partners />} />
              <Route path={"/gallery"} element={<Gallery />} />
              <Route path={"/faq"} element={<FAQ />} />
              <Route path={"/programs"} element={<Programs />} />
              <Route path={"/champions"} element={<Champions />} />
              <Route path={"/strategic-plane"} element={<StrategicPlane />} />
              <Route path={"/people-plane"} element={<PeoplePlane />} />
              <Route path={"/code-conduct"} element={<CodeofConduct />} />
              <Route path={"/memoradum"} element={<Memoradum />} />
              <Route path={"/gdpr"} element={<GDPR />} />
              <Route
                path={"/equality-diversity"}
                element={<EqualityDiversity />}
              />
              <Route path={"/health"} element={<Health />} />
              <Route path={"/policies"} element={<Policies />} />
              <Route
                path={"/board-of-directors"}
                element={<BoardOfDirectors />}
              />
              <Route path={"/reports"} element={<Reports />} />
              <Route path={"/privacy-policy"} element={<Privacy />} />
              <Route path={"/privacy-policy"} element={<Privacy />} />
              <Route path={"/leadership"} element={<LeadersDetails />} />
            </Routes>
          </div>
        </>
      )}
    </>
  );
}

export default App;
