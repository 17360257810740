export const senoirsfaq = [
  {
    question:
      "Do I have to register to be part of World Federation of British Kalari?",
    answer: `Yes, you need to register yourself as a World Federation of British Kalari (WFKA) member to attend our online, in-person classes and compete in our WKFA approved competitions.`,
  },
  {
    question: "Do I have to be in good shape before I start?",
    answer: `No, you will get into shape as you train. We have a series of exercises that you will learn as you progress through the ranking system which will enable you to perform the self defense techniques.`,
  },
  {
    question: `I am 41 years old, am I too old to start British Kalari?`,
    answer: `No, you are not too old to begin British Kalari training. British Kalari regularly has students start from adolescence through their 50's and older. British Kalari is a practical and effective martial art that will become a part of your life.`,
  },
  {
    question:
      "I am very busy with my work schedule, how much time do I need to devote to British Kalari classes?",
    answer: `Two 1 hour classes (online or in-person) per week are recommended, as is practicing at home. As you progress through the ranks you may practice more often which can further help hone your skills.`,
  },
  {
    question: `How long does it take to become a master/instructor (Aasan)?`,
    answer: `In British Kalari, it takes approximately 3 to 5 years to reach master/instructor (Aasan) level with commitment.`,
  },
];
