import React from "react";
import { PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";

const AntiDoping = () => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Anti-Doping Policy for British Federation of Kalari
          </Title>
          <br />
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            The British Federation of Kalari (BFK) is dedicated to upholding the
            principles of fair play, integrity, and respect in Kalari
            competitions. As such, BFK implements the following Anti-Doping
            Policy to ensure that athletes compete in a clean and drug-free
            environment and to uphold the values of sportsmanship and ethical
            conduct
            <br />
            <br />
            <b>1. Prohibited Substances and Methods:</b>
            <br />
            <br />
            <b>1.1. Prohibited Substances: </b>Athletes participating in
            BFK-sanctioned competitions are strictly prohibited from using
            substances listed on the World Anti-Doping Agency (WADA) Prohibited
            List, including anabolic steroids, stimulants, beta-blockers,
            diuretics, and peptide hormones. <br />
            <b> 1.2. Prohibited Methods: </b>Athletes are also prohibited from
            using any prohibited methods outlined in the WADA Prohibited List,
            including blood doping, manipulation of blood components, and
            tampering with urine samples
            <br />
            <br />
            <b>2. Ineligibility of Athletes:</b>
            <br />
            <br />
            <b>2.1. Positive Test Results: </b> Athletes who test positive for
            prohibited substances or methods, as determined by WADA-accredited
            testing protocols, will face disciplinary action, including but not
            limited to disqualification from competitions, suspension, and
            forfeiture of results and prizes.
            <br />
            <b> 2.2. Consequences for Support Personnel: </b>Any coaches,
            trainers, or other support personnel found to be involved in doping
            practices or facilitating the use of prohibited substances or
            methods by athletes will also face disciplinary action.
            <br />
            <br />
            <b> 3. Testing Procedures:</b> <br />
            <br /> <b>3.1. Testing Protocols: </b>BFK reserves the right to
            conduct drug testing at its sanctioned competitions in accordance
            with WADA guidelines and protocols. Athletes selected for testing
            must comply with all testing procedures and provide urine and/or
            blood samples as requested by doping control officers. <br />
            <b> 3.2. Out-of-Competition Testing:</b> Athletes may also be
            subject to out-of-competition testing to ensure compliance with
            anti-doping regulations at any time and place deemed appropriate by
            BFK and relevant anti-doping agencies. <br />
            <br />
            <b> 4. Therapeutic Use Exemptions (TUEs):</b>
            <br />
            <br />
            <b> 4.1. Medical Necessity:</b> Athletes with documented medical
            conditions requiring the use of prohibited substances may apply for
            a Therapeutic Use Exemption (TUE) in accordance with WADA guidelines
            and procedures. <br /> <b>4.2. Review Process:</b> All TUE
            applications will be reviewed by an independent panel appointed by
            BFK to ensure that exemptions are granted only in cases of genuine
            medical necessity and in compliance with anti-doping regulations.{" "}
            <br />
            <br /> <b>5. Education and Awareness:</b> <br />
            <br />
            <b>5.1. Education Programs:</b>
            BFK will provide education and awareness programs on anti-doping
            principles, rules, and regulations to athletes, coaches, officials,
            and other stakeholders involved in Kalari. <br />
            <b>5.2. Information Resources:</b> Informational resources on
            prohibited substances and methods, TUEs, testing procedures, and
            other relevant topics will be made available to all members of the
            Kalari community. <br />
            <br /> <b>6. Reporting and Confidentiality:</b> <br />
            <br />
            <b>6.1. Confidential Reporting:</b>
            Athletes, coaches, officials, and other stakeholders are encouraged
            to report any suspicions or concerns regarding doping practices to
            BFK in confidence. <br />
            <b>6.2. Protection of Whistleblowers:</b> BFK will take measures to
            protect the confidentiality and anonymity of individuals who report
            doping violations or provide information related to antidoping
            investigations. <br />
            <br /> <b>7. Compliance and Review:</b> <br />
            <br />
            <b>7.1. Compliance with WADA Standards:</b> BFK is committed to
            complying with the World AntiDoping Code and WADA standards for
            anti-doping programs in sport. <br />
            <b>7.2. Policy Review:</b> This Anti-Doping Policy will be reviewed
            regularly to ensure its effectiveness and compliance with the latest
            WADA regulations and guidelines. By adhering to this Anti-Doping
            Policy, the British Federation of Kalari reaffirms its commitment to
            promoting clean, fair, and ethical competition in Kalari and
            maintaining the integrity and reputation of the sport.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
    </PageWrapper>
  );
};

export default AntiDoping;
