import React, { ReactElement, useEffect, useState } from "react";
import {
  AnchorText,
  BrandName,
  HeaderContainer,
  HeaderImage,
  HeaderImageWrappper,
  HeaderWrapper,
  MobileAnchorText,
  MobileNavList,
  MobileNavOrderList,
  NavList,
  NavOrderList,
  SideMenuWrappper,
} from "./subcomponents";
import branding_logo from "../../assests/brand.png";
import Dropdown from "./dropdown";
import { useNavigate } from "react-router";
import { CanvaContianer, Offcanva } from "../../pages/Home/subcomponents";
import Aos from "aos";
import determineDropdown from "./determineDropdown";

const Header = (): ReactElement => {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState("");
  const [showCanvas, setshowCanvas] = useState(false);

  const onMouseEnter = (name: any) => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown(name);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown("");
    } else {
      setDropdown("");
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);

  return (
    <>
      <HeaderContainer>
        <HeaderWrapper>
          <HeaderImageWrappper>
            <HeaderImage src={branding_logo} alt="branding_logo" />
          </HeaderImageWrappper>
          <BrandName>
            <h4>BRITISH FEDERATION OF KALARI</h4>
            <h6>[ Affliated to World Federation of Kalari ]</h6>
          </BrandName>
          <SideMenuWrappper
            onClick={() => {
              setshowCanvas(!showCanvas);
            }}
          >
            <div>
              {" "}
              <span className="menu-line"></span>
              <span className="menu-line-small"></span>
              <span className="menu-line"></span>
            </div>
          </SideMenuWrappper>
          <CanvaContianer>
            <Offcanva
              show={showCanvas}
              scroll
              backdrop
              responsive={"lg"}
              autoFocus
              onHide={() => {
                setshowCanvas(false);
              }}
            >
              <Offcanva.Header closeButton>
                <div style={{ visibility: "hidden" }}>demo</div>
              </Offcanva.Header>
              <Offcanva.Body>
                <MobileNavOrderList>
                  <MobileNavList>
                    <MobileAnchorText
                      onClick={() => {
                        navigate("/");
                        setshowCanvas(false);
                      }}
                    >
                      Home
                    </MobileAnchorText>
                  </MobileNavList>{" "}
                  <MobileNavList
                    onClick={() => {
                      if (dropdown === "") {
                        setDropdown("about");
                      } else {
                        setDropdown("");
                      }
                    }}
                  >
                    <MobileAnchorText>about us</MobileAnchorText>

                    {dropdown === "about" && (
                      <div data-aos="fade-right" className="sub-menu">
                        {determineDropdown("about")?.map((data, index) => {
                          console.log(data, "about");
                          return (
                            <div key={index}>
                              <li
                                onClick={() => {
                                  navigate(data?.to);
                                  setshowCanvas(false);
                                }}
                              >
                                {data?.title}
                              </li>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </MobileNavList>{" "}
                  <MobileNavList
                    onClick={() => {
                      if (dropdown === "") {
                        setDropdown("gov");
                      } else {
                        setDropdown("");
                      }
                    }}
                  >
                    <MobileAnchorText>GOVERNANCE</MobileAnchorText>
                    {dropdown === "gov" && (
                      <div data-aos="fade-right" className="sub-menu">
                        {determineDropdown("gov")?.map((data, index) => {
                          console.log(data, "d");
                          return (
                            <div key={index}>
                              <li
                                onClick={() => {
                                  navigate(data?.to);
                                  setshowCanvas(false);
                                }}
                              >
                                {data?.title}
                              </li>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </MobileNavList>{" "}
                  <MobileNavList
                    onClick={() => {
                      navigate("/programs");
                      setshowCanvas(false);
                    }}
                  >
                    <MobileAnchorText>programs</MobileAnchorText>
                  </MobileNavList>{" "}
                  <MobileNavList
                    onClick={() => {
                      setDropdown("chap");
                    }}
                  >
                    <MobileAnchorText>championships</MobileAnchorText>
                    {dropdown === "chap" && (
                      <div data-aos="fade-right" className="sub-menu">
                        {determineDropdown("chap")?.map((data, index) => {
                          return (
                            <div key={index}>
                              <li
                                onClick={() => {
                                  navigate(data?.to);
                                  setshowCanvas(false);
                                }}
                              >
                                {data?.title}
                              </li>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </MobileNavList>{" "}
                  <MobileNavList>
                    <MobileAnchorText
                      onClick={() => {
                        navigate("/faq");
                        setshowCanvas(false);
                      }}
                    >
                      faq
                    </MobileAnchorText>
                  </MobileNavList>
                  <MobileNavList
                    onClick={() => {
                      setDropdown("news");
                    }}
                    onMouseLeave={onMouseLeave}
                  >
                    <MobileAnchorText>news</MobileAnchorText>{" "}
                    {dropdown === "news" && (
                      <div data-aos="fade-right" className="sub-menu">
                        {determineDropdown("news")?.map((data, index) => {
                          return (
                            <div key={index}>
                              <li
                                onClick={() => {
                                  navigate(data?.to);
                                  setshowCanvas(false);
                                }}
                              >
                                {data?.title}
                              </li>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </MobileNavList>
                </MobileNavOrderList>
              </Offcanva.Body>
            </Offcanva>
          </CanvaContianer>
          <NavOrderList>
            <NavList>
              <AnchorText
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </AnchorText>
            </NavList>{" "}
            <NavList
              onMouseEnter={() => {
                onMouseEnter("about");
              }}
              onMouseLeave={onMouseLeave}
            >
              <AnchorText>about us</AnchorText>
              {dropdown === "about" && <Dropdown name={"about"} />}
            </NavList>{" "}
            <NavList
              onMouseEnter={() => {
                onMouseEnter("gov");
              }}
              onMouseLeave={onMouseLeave}
            >
              <AnchorText>governance</AnchorText>{" "}
              {dropdown === "gov" && <Dropdown name={"gov"} />}
            </NavList>{" "}
            <NavList>
              <AnchorText
                onClick={() => {
                  navigate("/programs");
                }}
              >
                programs
              </AnchorText>
            </NavList>{" "}
            <NavList
              onMouseEnter={() => {
                onMouseEnter("chap");
              }}
              onMouseLeave={onMouseLeave}
            >
              <AnchorText>championships</AnchorText>
              {dropdown === "chap" && <Dropdown name={"chap"} />}
            </NavList>
            <NavList>
              <AnchorText
                onClick={() => {
                  navigate("/faq");
                }}
              >
                faq
              </AnchorText>
            </NavList>
            <NavList
              onMouseEnter={() => {
                onMouseEnter("news");
              }}
              onMouseLeave={onMouseLeave}
            >
              <AnchorText>news</AnchorText>{" "}
              {dropdown === "news" && <Dropdown name={"news"} />}
            </NavList>
          </NavOrderList>
        </HeaderWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
