import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import home from '../../../assests/11031849.jpg'

export const ContactForstWrapper = styled.div`
  background-image: url(${home});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  position: relative;
  width: 100%;
  background-color: rgba(244, 244, 244, 0.91);
`;

export const FormInput = styled(FormControl)`
  background: #f5f5f5;
  margin: 20px 0;
  border-radius: 6px;
  height: 48px;
  &::placeholder {
    color: #1a1668;
    font-size: 15px;
    font-weight: 400;
  }
  .form-control:focus {
    box-shadow: none;
  }
`;
