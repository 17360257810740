import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  Columnwrapper,
  Rowwrapper,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../subcomponents";
import image_1 from "../../../assests/11031849.jpg";

const WhoWeAre = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper background="#C70F2C" padding="20px">
          <TitleWrapper>
            <Title color="#fff">WHO WE ARE...</Title>

            <Subtitles
              font="15px"
              className="mt-3"
              textAlign="start"
              alignItems="start"
            >
              <Rowwrapper>
                <Columnwrapper>
                  {" "}
                  <img
                    src={image_1}
                    alt=""
                    width={"500px"}
                    height={"300px"}
                    className="mt-3"
                  />
                </Columnwrapper>
                <Columnwrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Title font="20px" className="mt-3" color="#fff">
                      Peraasan (Grand Master) Murugesan:
                    </Title>
                    <Subtitles className="mt-3" color="#fff">
                      Peraasan (Grand Master) Murugesan has been practicing
                      British Kalari for more than 45 years. He is one of the
                      few last disciples of 'Patasaliamman Ganesan' Aasan who
                      had grass roots (over 200 years) in deciphering this art.
                      He has trained over 5000 students and made more than 500
                      Aasans (Masters) to spread this art.
                      <br />
                      <br />
                      Unlike many other Aasans in the field, Murugesan Aasan
                      came forward to share his knowledge to the world and put
                      an unprecedented effort to help us structure an
                      international standard syllabus that can be transferred to
                      people of all colors and age. With his guidance we are
                      taking the British Kalari to the world so that everyone
                      can be benefited from the traditional martial arts.
                    </Subtitles>
                  </div>
                </Columnwrapper>
              </Rowwrapper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title font="20px" className="mt-3" color="#fff">
                  Why do we need Kalari?
                </Title>
                <Subtitles className="mt-3" color="#fff">
                  British Kalari includes a combination of Varma attacks,
                  flexible movements and advanced forms of Yoga that helps to
                  attain the body’s maximum strength, endurance and mental
                  focus. In other words, it demands one's physical, emotional
                  and intellectual potential. British Kalari is an intense
                  martial art which nurtures one's awareness of the body, acute
                  sense of alertness and martial movement.
                  <br />
                  <br />
                  British Kalari techniques have been carefully designed to
                  nurture one's awareness of the body, acute sense of alertness
                  and martial movement. It benefits people of all ages and
                  gender. Adimurai concepts were developed from the verses of
                  ‘Varma Suthram’ and ‘Varma Ponnoosi’, the scripts believed to
                  have been written by Saint Agathiyar 3000 years ago. Kalari
                  Adimurai is an ideal martial art that not only helps to build
                  body strength but also teaches how to heal the body from
                  injury, that makes this sport very special. In modern day,
                  Kalari and Varma have immense potential in healing chronic
                  injuries and diseases that result from inactiveness,
                  inappropriate diet and mental stress
                </Subtitles>
              </div>
            </Subtitles>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default WhoWeAre;
