import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  ImageSection,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import banner_image from "../../../assests/banner.jpg";

const AboutIjama = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        <SectionWrapper background="#e4284a" padding="20px">
          <Title color="#fff" textAlign="center" alignItems="center">
            ABOUT IJAMA
          </Title>
          <br />
          <br />
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            AIM:
          </Title>
          <Subtitles color="#fff" font="15px" className="mt-3">
            IJAMA, a Journal of Multidisciplinary Educational Research focuses
            on critical and creative research. This publication aims to support
            unique research and foster frank discussions between traditional and
            cutting-edge ideas. ​
          </Subtitles>{" "}
          <br />
          <Title color="#fff" font="17px">
            SCOPE:
          </Title>
          <Subtitles color="#fff" font="15px" className="mt-3">
            The journal promotes original academic research in Martial Arts,
            Sports Sciences, Humanities, Culture, Social Sciences, Physical
            Education, providing an insight, to transform lives is the motto. ​
          </Subtitles>{" "}
          <br />
          <Title color="#fff" font="17px">
            OBJECTIVES:
          </Title>
          <Subtitles color="#fff" font="15px" className="mt-3">
            To publish original empirical research and theoretical studies on
            multi-disciplinary aspects of martial arts, human response to
            exercise, Sports medicine, Performance analysis, Biomechanics
            studies. The object is simple and that is to provide a forum for
            scholarly dialogue on a broad range of topics significant to our
            times and cultures.​
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default AboutIjama;
