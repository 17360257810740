import styled from "styled-components";
import image from "../../assests/11031849.jpg";

export const ChampionWrapper = styled.div`
  height: 90vh;
  background-image: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-attachment: fixed;
  width: 100%;
`;
