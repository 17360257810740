/* eslint-disable jsx-a11y/anchor-is-valid */
import { Accordion } from "react-bootstrap";
import {
  AccordianHeader,
  AccordianStyle,
  AccordianItem,
} from "./styledComponent";
import { AccordianProps } from "./types";

const Accordian = ({ data }: AccordianProps) => {
  return (
    <AccordianStyle defaultActiveKey="1">
      {data?.map((lists, index) => {
        return (
          <AccordianItem eventKey={index} key={index}>
            <AccordianHeader>
              <a>{lists?.question}</a>
            </AccordianHeader>
            <Accordion.Body>
              <p>{lists?.answer}</p>
            </Accordion.Body>
          </AccordianItem>
        );
      })}
    </AccordianStyle>
  );
};

export default Accordian;
