import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  PartnerImages,
  SectionContainer,
  SectionWrapper,
  Partnerss,
  Title,
} from "../../subcomponents";

const Partners = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper background="#C70F2C" padding="20px">
          {" "}
          <Title color="#fff" textAlign="center" alignItems="center">
            OUR PARTNERS
          </Title>
          <PartnerImages>
            <Partnerss src="" />
          </PartnerImages>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Partners;
