import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../components";
import { ChampionWrapper } from "./subcomponents";
import {
  Columnwrapper,
  Rowwrapper,
  SectionContainer,
  SectionWrapper,
  Title,
  TitleWrapper,
} from "../subcomponents";
import { ProgramsButton } from "../Programs/subcomponents";

const Champions = (): ReactElement => {
  return (
    <PageWrapper>
      <ChampionWrapper></ChampionWrapper>
      <SectionContainer>
        <SectionWrapper>
          <Rowwrapper>
            <Columnwrapper>
              <div>
                <Title font="19px" textAlign="center" alignItems="center">
                  Competition <i className="bx bxs-calendar calendar"></i>
                </Title>
                <Title
                  color="green"
                  font="19px"
                  textAlign="center"
                  alignItems="center"
                >
                  National Championship
                </Title>
                <Title
                  color="red"
                  font="19px"
                  textAlign="center"
                  alignItems="center"
                >
                  18th February 2024
                </Title>
              </div>
            </Columnwrapper>
            <Columnwrapper>
              {" "}
              <div>
                <Title font="19px" textAlign="center" alignItems="center">
                  2024 Competition Schedule
                  <i className="bx bxs-calendar calendar"></i>
                </Title>
                <Title
                  color="red"
                  font="17px"
                  textAlign="center"
                  alignItems="center"
                >
                  1st Indian National Kalari and Silambam Championship
                </Title>
                <Title font="15px" textAlign="start" alignItems="start">
                  World Federation of Kalari is gearing up to conduct the 1ST
                  Indian National Kalari and Silambam Championship,{" "}
                  <span style={{ color: "green" }}>
                    18th February 2024 @ Coimbatore.
                  </span>
                  <br />
                  Detailed event schedule and time will be shared once the
                  entries are confirmed and finalized. <br />
                  <br /> Each participant can register for two Chuvadu Event and
                  one Silambam event and/or one Vel Kambu Event of their choice
                  based on their skill level.
                </Title>
                <ProgramsButton className="w-100">
                  Championship - Prospectus
                </ProgramsButton>
                <ProgramsButton className="w-100">
                  Championship - Prospectus
                </ProgramsButton>
              </div>
            </Columnwrapper>
            <Columnwrapper>
              {" "}
              <div>
                <Title font="19px" textAlign="start" alignItems="start">
                  Age
                </Title>
                <Title font="17px" textAlign="start" alignItems="start">
                  The competitions will be held for both boys and girls under
                  six different age categories as given below.
                </Title>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <ul
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    <li className="p-1">6-8</li>
                    <li className="p-1">8-10</li>
                    <li className="p-1">10-12</li>
                    <li className="p-1">12-14</li>
                    <li className="p-1">14-16</li>
                    <li className="p-1">16-18</li>
                  </ul>
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      left: "30px",
                      fontWeight: 600,
                    }}
                  >
                    <li className="p-1">6-8</li>
                    <li className="p-1">8-10</li>
                    <li className="p-1">10-12</li>
                    <li className="p-1">12-14</li>
                    <li className="p-1">14-16</li>
                    <li className="p-1">16-18</li>
                  </ul>
                </div>
              </div>
            </Columnwrapper>
          </Rowwrapper>
        </SectionWrapper>{" "}
        <SectionWrapper>
          <Rowwrapper>
            <Columnwrapper className="mt-5">
              <TitleWrapper alignItems="start" textAlign="start">
                <Title font="19px" textAlign="start" alignItems="start">
                  Types of Events
                </Title>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <ul
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    <li className="p-1">Chuvadu Championship </li>
                    <li className="p-1">Silambam Championship</li>
                    <li className="p-1">Vel Kambu Championship</li>
                  </ul>
                </div>
              </TitleWrapper>
            </Columnwrapper>
            <Columnwrapper className="mt-5">
              {" "}
              <div>
                <Title font="19px" textAlign="center" alignItems="center">
                  Rules of the Competition
                </Title>

                <Title font="15px" textAlign="start" alignItems="start">
                  1. Participant entry will be confirmed against the payment of
                  entry fee Rs.300/- per participant for 1 event (Chuvadu only
                  or Silambam only). <br />
                  <br />
                  2. Rs.400/- for 2 events (Chuvadu Events & Silambam Event) and
                  Rs.500/- for 3 events (Chuvadu Events, Silambam Event & Vel
                  Kambu Event). <br />
                  <br />
                  3. The last date of entry registration on portal / Via Google
                  entry form will be on or before 02 FEB, 2024.
                </Title>
              </div>
            </Columnwrapper>
            <Columnwrapper></Columnwrapper>
          </Rowwrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Champions;
