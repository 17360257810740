import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";

const StrategicPlane = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          {" "}
          <Title color="black" textAlign="center" alignItems="center">
            Strategic Growth Plan - 5 Year ( 2024 – 2029 )
          </Title>
          <br />
          <Subtitles color="black" font="14px" fontWeight={400}>
            Creating a strategic growth plan for developing Kalari Martial Art
            in the UK over the next five years requires careful consideration of
            various factors including market analysis, target audience
            identification, training infrastructure, marketing strategies, and
            partnership development. Here's a structured plan to guide the
            growth.
            <br />
            <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Year 1: Foundation and Awareness
            </Title>
            <br />
            <b> Market Research and Analysis:</b> <br /> Conduct thorough market
            research to understand the demand for martial arts, especially niche
            ones like Kalari, in the UK. Identify potential competitors, their
            offerings, pricing strategies, and target demographics.
            <br />
            <br />
            <b>Establishment of Training Centers:</b> <br />
            Set up a flagship training center in a strategic location,
            preferably in a metropolitan area with a diverse population. Hire
            qualified Kalari instructors, either by training locals or by
            bringing in experts from Kerala, India.
            <br />
            <br />
            <b>Awareness Campaigns:</b>
            <br />
            Launch marketing campaigns targeting martial arts enthusiasts,
            fitness enthusiasts, and individuals interested in holistic
            wellness. Utilize social media, local events, and workshops to
            create awareness about Kalari's benefits and its unique features.
            <br />
            <br />
            <b>Partnerships and Collaborations:</b>
            <br /> Collaborate with gyms, wellness centers, and schools to offer
            Kalari workshops and classes. Partner with local cultural
            organizations to showcase Kalari as a traditional Indian martial art
            form. <br />
            <br />
            <b> Student Recruitment and Retention:</b> <br />
            Offer introductory classes and trial sessions to attract students.
            Implement a referral program to encourage existing students to bring
            in new members. Focus on providing high-quality training and
            personalized attention to ensure student satisfaction and retention.
            <br />
            <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Year 2-3: Expansion and Consolidation
            </Title>
            <br />
            <b> Branch Expansion:</b> <br /> Expand the presence by opening
            additional training centers in key cities and towns across the UK.
            Ensure consistency in training quality and curriculum across all
            branches.
            <br />
            <br />
            <b>Diversification of Offerings:</b> <br />
            Introduce specialized classes for different demographics such as
            children, seniors, women, and athletes. <br /> Offer workshops on
            Kalari massage therapy and healing techniques to attract individuals
            interested in alternative medicine.
            <br />
            <br />
            <b>Online Presence and E-Learning:</b>
            <br />
            Develop an online platform for remote learning, providing access to
            instructional videos, live classes, and educational resources.{" "}
            <br /> Offer certification programs for Kalari instructors to
            attract aspiring martial arts teachers and enthusiasts globally.
            <br />
            <br />
            <b>Community Engagement:</b>
            <br /> Organize community events, seminars, and demonstrations to
            engage with the local community and showcase Kalari's cultural
            heritage. <br /> Partner with local authorities and organizations to
            integrate Kalari into school curriculums and community wellness
            programs.
            <br />
            <br />
            <b> International Collaboration:</b> <br />
            Establish partnerships with Kalari practitioners and organizations
            in India and other countries to exchange knowledge, organize joint
            training programs, and promote cross-cultural understanding.
            <br />
            <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Year 4-5: Scaling and Sustainability
            </Title>
            <br />
            <b> Franchise Model:</b> <br /> Explore franchising opportunities to
            further expand the reach of Kalari Martial Art across the UK and
            potentially into neighboring countries. <br /> Develop a
            comprehensive franchise model including training protocols,
            marketing support, and operational guidelines.
            <br />
            <br />
            <b>Corporate Wellness Programs:</b> <br />
            Target corporate clients by offering Kalari workshops and wellness
            programs as part of their employee wellness initiatives. <br />{" "}
            Customize programs to address stress management, team building, and
            leadership development within corporate settings.
            <br />
            <br />
            <b>Research and Development:</b>
            <br />
            Invest in research and development to enhance Kalari training
            methodologies, develop new training equipment, and innovate in areas
            such as sports performance and rehabilitation.
            <br />
            <br />
            <b>Sustainability Initiatives::</b>
            <br /> Incorporate eco-friendly practices into the operations of
            training centers, such as recycling, energy efficiency, and reducing
            carbon footprint. <br /> Partner with environmental organizations to
            promote sustainability and raise awareness about environmental
            issues through Kalari events and campaigns.
            <br />
            <br />
            <b> Recognition and Accreditation:</b> <br />
            Work towards gaining recognition and accreditation from relevant
            martial arts federations and sports governing bodies in the UK,
            which can enhance the credibility and legitimacy of Kalari Martial
            Art as a mainstream discipline. <br />
            By following this strategic growth plan, Kalari Martial Art can
            establish a strong presence in the UK, attract a diverse student
            base, and contribute to the promotion of traditional Indian martial
            arts on a global scale.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default StrategicPlane;
