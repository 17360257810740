import { Accordion } from "react-bootstrap";
import styled from "styled-components";

export const AccordianStyle = styled(Accordion)`
  width: 90%;
  margin: 20px auto;
`;

export const AccordianItem = styled(Accordion.Item)`
  margin: 10px 0;
  padding: 0 15px;
  border-radius: none;
  border: none;
  p {
    font-size: 15px;
    font-weight: 600;
    line-height: 26px;
    color: #686976;
    letter-spacing: 0.2px;
  }
`;

export const AccordianHeader = styled(Accordion.Header)`
  a {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
    font-weight: 400;
  }
  a:not([href]):not([class]) {
    color: #242864;
    transition: 0.2s all;
  }
  span {
    color: #242864;
    font-size: 20px;
    padding-right: 10px;
    line-height: 30px;
    font-weight: 600;
    transition: 0.2s all;
  }
  &:hover {
    a:not([href]):not([class]),
    span {
      color: #f54881;
      transition: 0.2s all;
    }
  }
`;
