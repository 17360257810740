import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../subcomponents";

const WhatweDo = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper background="#C70F2C" padding="20px">
          <TitleWrapper>
            <Title color="#fff">WHAT WE DO...</Title>
            <Subtitles
              font="15px"
              className="mt-3"
              color="#fff"
              textAlign="start"
              alignItems="start"
            >
              Through British Kalari, we are reviving our ancient practices of
              Varmam, Yoga and Siddha to recondition our body and mind. Adding
              the elements of ancient martial arts to it not only brings the
              self-realization of our physical and mental strength, but also
              helps us stay focused and motivated. As our modern world is filled
              with political and social contents that fuel our mind with fear,
              hate, jealousy and greed, a regular practice of British Kalari
              help us to stay focussed, positive and content.
              <br />
              <br />
              With a regular practice of British Kalari, many of our students
              are feeling that they are getting physically stronger and
              flexible, mentally calmer and peaceful, and experienced a sense of
              satisfaction in their life. We believe British Kalari will be a
              good practice to reach harmony with oneself.
            </Subtitles>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default WhatweDo;
