import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Title,
  Subtitles,
} from "../../subcomponents";

const Memoradum = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Memorandum Articles
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-5"
          >
            <span style={{ color: "red" }}>
              {" "}
              Download British Kalari’s Articles of Association (November 2019)
            </span>
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Memoradum;
