import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  ImageSection,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import banner_image from "../../../assests/banner.jpg";

const AuthorGuidliness = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        <SectionWrapper background="#e4284a" padding="20px">
          <Title color="#fff" textAlign="center" alignItems="center">
            AUTHOR GUIDELINES
          </Title>
          <br />
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            TYPE OF ARTICLES CONSIDERED
          </Title>
          <Subtitles color="#fff" font="15px" className="mt-3">
            IJAMA is a multidisciplinary journal and therefore research findings
            (papers), reviews, case reports, letters to the editor of general
            significance that are written clearly and well organized will be
            given preference. It invites papers under the following categories:
            <br />
            IJAMA is a multidisciplinary journal and therefore research findings
            (papers), reviews, case reports, letters to the editor of general
            significance that are written clearly and well organized will be
            given preference. It invites papers under the following categories:
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                left: "40px",
              }}
            >
              <span>1. Research papers</span>{" "}
              <span>2.Reviews/special articles*</span>{" "}
              <span>3.Case reports</span>
              <span>4.Letters to the Editor**</span>
            </div>{" "}
            <br />
            *Reviews and Special articles will be considered from senior
            scientists **A letter can be a brief scientific communication
            <br />
            In general, the length of full research paper should not exceed six
            (6) printed pages of the journal with each page containing
            approximately 450-500 words. Reviews/special articles can go up to 8
            printed pages. Case report is limited to 4 pages and letter to the
            editor 2 pages in print.
            <br />
            <br />
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            THE FORMAT OF EACH RESEARCH PAPER SHOULD BE AS FOLLOWS
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            Title page having title, authors, address, corresponding author's
            name, email id, abstract and key words
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                left: "40px",
              }}
            >
              <span>1.Introduction</span> <span>2.Materials and Methods</span>{" "}
              <span>3.Results and Discussions</span>
              <span>4.Conclusions</span>
              <span>5.Acknowledgement (if any)</span>
              <span>6.References</span>
            </div>
            <br />
            *Reviews and Special articles will be considered from senior
            scientists **A letter can be a brief scientific communication
            <br />
            In general, the length of full research paper should not exceed six
            (6) printed pages of the journal with each page containing
            approximately 450-500 words. Reviews/special articles can go up to 8
            printed pages. Case report is limited to 4 pages and letter to the
            editor 2 pages in print.
            <br />
            <br />
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            TITLE PAGE
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            It should contain, in the following order, the complete 'Title' with
            sub-title, if any, of the research work, followed by a short title
            for use in folio line; complete name(s) of the author(s); respective
            affiliation(s) of the author(s); an 'abstract' (briefly, in about
            200 words, it should contain the purpose, methods, results and
            conclusion(s) of the paper); 3-5 'keywords; and at the bottom,
            complete contact details of the corresponding author.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            INTRODUCTION
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            It should provide sufficient information on the topic of research to
            help the reader(s) understand and evaluate the presented study.
            Previous work should be cited correctly while avoiding a lengthy
            review on the topic. It should clearly outline the scientific
            hypothesis that was analyzed and the rationale for the study and the
            experiments undertaken.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            MATERIALS AND METHODS
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            This section must be detailed enough to allow reproduction of the
            experiments described. Novel methods and relevant modification, as
            well as genuine technical innovations, have to be described
            completely, but there is no need for repeating methods that are
            published in detail elsewhere. Also, authors should remember to do
            the following:
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                left: "40px",
              }}
            >
              <span>
                {" "}
                1)Clearly mention the department and institution where the
                research work was carried out.
              </span>{" "}
              <span>
                {" "}
                2) Study group or sampling method should be clearly mentioned.
                Period(s) when the study was carried out need(s), if necessary.{" "}
              </span>
              <span>
                {" "}
                3) The method(s) and procedure(s) should be explicitly stated to
                enable other research workers to reproduce the result(s), if
                necessary.{" "}
              </span>
              <span>
                {" "}
                4) Provide reference(s) and brief description(s) for methods(s)
                that has/have been published but is/are not well known.{" "}
              </span>
              <span>
                {" "}
                5) Describe new or substantially modified method(s). Give
                reasons for using it/them. Indicate its/their limitation(s).{" "}
              </span>
              <span>
                {" "}
                6) Established method(s) can be just mentioned with reference.{" "}
              </span>
              <span>
                {" "}
                7) If the procedure(s) involve(s) an assay, include data on its
                accuracy, specificity, sensitivity and reproducibility.{" "}
              </span>
              <span>
                {" "}
                8) Details of statistical method(s) adopted for analysis of data
                should be described.{" "}
              </span>
              <span>
                {" "}
                9) The statistical analysis and significance of the findings
                when appropriate should be mentioned.
              </span>
            </div>
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            RESULTS AND DISCUSSIONS
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            This section should present the data as clearly as possible. Authors
            are encouraged to describe the rationale for the experimental
            design, but extensive interpretation must be reserved for the
            discussion. Please avoid redundancy in the presentation of data.
            Results in the form of a figure may not be presented additionally as
            a table or vice-versa. Only important data should be mentioned.
            Statistical tests and values must be mentioned in tables. Only
            important observations need to be emphasized or summarized
            <br />
            <br />
            Necessary tables and figures should be in appropriate places in the
            text. Tables should not be placed in boxes but in plain format. The
            caption for the table should be given at the top of the table.
            Statistics relevant to the data should be given as foot note under
            the table.
            <br />
            Discussion It should give the main conclusion(s) from the
            experimental data without lengthy repetition and should interpret
            the same with respect to relevant literature. The discussion should
            deal with the interpretation of results and avoid repeating what has
            already been presented under results. The conclusion(s) can be
            linked with the goal(s) of the study.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            CONCULUSIONS
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            It ought to be succinct and interesting, giving the reader a clear
            comprehension of your key results and the resolution to your
            research issue.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            ACKNOWLEDGEMENT(S)
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            In this section, the authors(s) may thank/acknowledge personal,
            institutional and financial assistance received to undertake
            research work related to the manuscript.
          </Subtitles>{" "}
          <Title color="#fff" font="17px" alignItems="start" textAlign="start">
            REFERENCES
          </Title>{" "}
          <Subtitles color="#fff" font="15px" className="mt-3">
            As per the APA latest edition guidelines
          </Subtitles>{" "}
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default AuthorGuidliness;
