import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import { Footer, PageWrapper } from "../../../components";
import BoardCard from "./helper";
import user_images from "../../../assests/board/board.png";

const BoardOfDirectors = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            About the British Kalari Board
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            The Board of British Kalari lead the strategic direction of the
            organisation. The Board consists of people from both Kalari and
            non-Kalari backgrounds and meet quarterly.
          </Subtitles>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <BoardCard
              name="Murugupandian Pandian"
              role="Chairperson / President"
              image={user_images}
              type="Executive Director"
            />{" "}
            <BoardCard
              name="Dilip Pushpa Rajan"
              role="Secretary"
              image={user_images}
              type="Executive Director"
            />{" "}
            <BoardCard
              name="Rajarajeswari Lakshmanan"
              role="Treasurer"
              image={user_images}
              type="Executive Director"
            />{" "}
            <BoardCard
              name="Ramesh Rathinakumar"
              role="President–WFK"
              image={user_images}
              type="Executive Director"
            />
            <BoardCard
              name="Senthil Rajkumar"
              role="CEO-WFK"
              image={user_images}
              type="Executive Director"
            />
            <BoardCard
              name="TBC"
              role="Member"
              image={user_images}
              type="Non-Executive Director"
            />
            <BoardCard
              name="TBC"
              role="Member"
              image={user_images}
              type="Non-Executive Director"
            />
          </div>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default BoardOfDirectors;
