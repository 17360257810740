import React, { ReactElement } from "react";
import styled from "styled-components";
import { Subtitles, Title, TitleWrapper } from "../../subcomponents";

export const CardWrapper = styled.div`
  padding: 10px;
  border: 1px solid transparent;
  width: 250px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 10px;
`;

interface BoardProsp {
  name: string;
  role: string;
  image?: string;
  type: string;
  subtitle?: string;
}

const BoardCard = ({
  name,
  role,
  image,
  type,
  subtitle,
}: BoardProsp): ReactElement => {
  return (
    <CardWrapper>
      <div>
        <img src={image} alt="person_image" width={"150px"} />
      </div>
      <TitleWrapper className="mt-3">
        <Title font="15px">{name}</Title>
        <div>
          {role && <Subtitles font="13px">Role: {role}</Subtitles>}
          {type && (
            <Subtitles font="13px" style={{ marginTop: "-13px" }}>
              Director Type: {type}
            </Subtitles>
          )}
          <Subtitles
            font="13px"
            className="mt-3"
            style={{ marginTop: "-13px" }}
          >
            {subtitle}
          </Subtitles>
        </div>
      </TitleWrapper>
    </CardWrapper>
  );
};

export default BoardCard;
