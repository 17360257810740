import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ProgramsButton = styled(Button)`
  border-radius: 0;
  border: 2px solid white;
  padding: 10px;
  &:hover {
    border: 3px solid black;
    background-color: transparent;
  }
`;
