import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Title,
  Subtitles,
} from "../subcomponents";
import { Footer, PageWrapper } from "../../components";

const Privacy = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Privacy Policy
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            British Kalari is a privacy conscious organisation and is strongly
            committed to your right to privacy. Our Data Protection Policy
            follows the guidelines set out in General Data Protection
            Regulations (May 2018). <br />
            <br />
            The Principles of the General Data Protection Regulations (Under
            Article 5) are that data shall be:
            <br />
            <br />
            1.processed lawfully, fairly and in a transparent manner in relation
            to individuals;
            <br />
            <br />
            2.collected for specified, explicit and legitimate purposes and not
            further processed in a manner that is incompatible with those
            purposes; further processing for archiving purposes in the public
            interest, scientific or historical research purposes or statistical
            purposes shall not be considered to be incompatible with the initial
            purposes;
            <br />
            <br />
            3.adequate, relevant and limited to what is necessary in relation to
            the purposes for which they are processed;
            <br />
            <br />
            4.accurate and, where necessary, kept up to date; every reasonable
            step must be taken to ensure that personal data that are inaccurate,
            having regard to the purposes for which they are processed, are
            erased or rectified without delay;
            <br />
            <br />
            5.kept in a form which permits identification of data subjects for
            no longer than is necessary for the purposes for which the personal
            data are processed; personal data may be stored for longer periods
            insofar as the personal data will be processed solely for archiving
            purposes in the public interest, scientific or historical research
            purposes or statistical purposes subject to implementation of the
            appropriate technical and organisational measures required by the
            GDPR in order to safeguard the rights and freedoms of individuals;
            and
            <br />
            <br />
            6.processed in a manner that ensures appropriate security of the
            personal data, including protection against unauthorised or unlawful
            processing and against accidental loss, destruction or damage, using
            appropriate technical or organisational measures.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Privacy;
