import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  Columnwrapper,
  ImageSection,
  Rowwrapper,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../subcomponents";
import banner_image from "../../../assests/banner.jpg";
import image_1 from "../../../assests/history/his_1.jpeg";
import image_2 from "../../../assests/history/his_2.jpeg";
import image_3 from "../../../assests/history/WhatsApp Image 2021-01-31 at 5_36_48 PM.jpeg";

const History = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        <SectionWrapper background="#C70F2C" padding="20px">
          <TitleWrapper>
            <Title color="#fff">OUR HISTORY...</Title>
            <Title
              font="20px"
              className="mt-3"
              color="#fff"
              textAlign="start"
              alignItems="start"
            >
              Sangam Period - 600 BC Era
            </Title>

            <Subtitles font="15px" className="mt-3" color="#fff">
              The history of Kalari martial arts can be traced back to the
              Sangam period, an ancient era in the southern part of the Indian
              subcontinent, particularly in the present-day Indian states of
              Tamil Nadu and parts of Kerala. The Sangam period, estimated to
              have lasted from 600 BC to 600 AD, was characterized by the
              flourishing of literature, arts, and various cultural practices,
              including martial arts like Kalari.
              <br />
              <br />{" "}
              <Title
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                Sangam Literature:
              </Title>{" "}
              <Subtitles color="#fff" alignItems="start" textAlign="start">
                <ul>
                  <li>
                    Silappathikaram and Manimekalai: These two Tamil epics from
                    the Sangam period, Silappathikaram and Manimekalai, provide
                    glimpses into the martial traditions of the time. References
                    to warriors and their combat skills suggest the existence of
                    early forms of martial arts training.
                  </li>
                  <li>
                    Purananuru and Agananuru: These are the two major literature
                    that captures the effect of wars on human lives during the
                    Sangam period and each comprises 400 poems that were written
                    by prominent poets. Both the literature refer to the
                    frequent wars and conflicts among the Chera, Chola and
                    Pandya dynasties and the word ‘Kalari’ has been quoted very
                  </li>
                </ul>
              </Subtitles>
              <br />
              <Subtitles
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                <div
                  style={{ width: "400px", position: "relative", left: "50px" }}
                >
                  “களரி மருங்கில் கால்பெயர்த் தாடி ஈம விளக்கின் வெருவரப் பேரும்
                  காடுமுன் னினரே, நாடுகொண் டோரும்!” Purananuru, 359, Author:
                  Kaavatanaar
                </div>
              </Subtitles>{" "}
              <br />
              <Title
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                Kalari in Ancient Texts:
              </Title>{" "}
              <Subtitles color="#fff" alignItems="start" textAlign="start">
                <ul>
                  <li>
                    Agastya and Dhanurveda: The sage Agastya, a revered figure
                    in Indian mythology, is often associated with the
                    development of martial arts, including Kalari. Dhanurveda,
                    the ancient Indian science of archery and warfare, is
                    believed to have influenced the early stages of Kalari
                    training.
                  </li>
                </ul>
              </Subtitles>{" "}
              <Title
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                Practical Application in Warfare:
              </Title>{" "}
              <Subtitles
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                <ul>
                  <li>
                    Sangam Era Battles: The Sangam period was marked by frequent
                    wars and conflicts among various Tamil dynasties. Martial
                    skills, including those taught in Kalari, would have been of
                    practical importance for warriors engaged in these battles.
                    The effectiveness of these combat techniques likely
                    contributed to the continued practice and development of
                    Kalari.
                  </li>
                </ul>
              </Subtitles>
              <Title
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                Cultural Integration:
              </Title>{" "}
              <Subtitles
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                <ul>
                  <li>
                    Rituals and Festivals: Kalari was not only a practical skill
                    for self-defense and warfare but also an integral part of
                    the cultural fabric. It was often associated with religious
                    rituals, festivals, and performances. The integration of
                    Kalari into various aspects of daily life helped to preserve
                    and propagate the martial art.
                  </li>
                </ul>
              </Subtitles>
              <Title
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                Gurukul System:
              </Title>{" "}
              <Subtitles
                color="#fff"
                font="17px"
                alignItems="start"
                textAlign="start"
              >
                <ul>
                  <li>
                    During the Sangam period, martial arts like Kalari were
                    likely transmitted through a traditional Gurukul system,
                    where students lived with their gurus (teachers) and
                    underwent rigorous training. This method of transmission
                    helped preserve the authenticity and depth of knowledge
                    associated with Kalari. daily life helped to preserve and
                    propagate the martial art.
                  </li>
                </ul>
              </Subtitles>
            </Subtitles>
          </TitleWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              margin: "0 0 0 auto",
              width: "500px",
            }}
          >
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Post Sangam Period - 1000 AD Era
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              The post-Sangam period marked a transitional phase in the history
              of Kalari martial arts. During this time, various regional
              kingdoms and empires emerged in South India, influencing the
              evolution of martial traditions. While the Sangam literature
              provided insights into early martial practices, the post-Sangam
              period saw the refinement and expansion of Kalari, laying the
              groundwork for its continued development.
            </Subtitles>
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Regional Dynasties and Kingdoms:
            </Title>{" "}
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Cheras, Cholas, Pandyas, and Pallavas: The post-Sangam period
                  witnessed the rise of powerful regional dynasties, including
                  the Cheras, Cholas, Pandyas, and Pallavas. These kingdoms
                  often engaged in military conflicts, and the martial
                  traditions, including Kalari, were essential for the defense
                  and expansion of their territories.
                </li>
              </ul>
            </Subtitles>
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Integration with Religious Practices:
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Temple Art Forms: Kalari became closely integrated with
                  religious practices during the post-Sangam period. It found
                  expression in temple art forms, rituals, and festivals. The
                  association with religious contexts helped preserve and
                  promote Kalari as both a practical martial art and a cultural
                  tradition.
                </li>
              </ul>
            </Subtitles>
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Codification and Systematization:
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Formation of Gurukul System: The post-Sangam period witnessed
                  the formalization and codification of martial arts training,
                  including Kalari. The Gurukul system of education, where
                  students lived with their gurus (Aasans) in a dedicated
                  training environment, became more structured. This helped in
                  the systematic transmission of knowledge and skills from one
                  generation to the next.
                </li>
              </ul>
            </Subtitles>
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Weaponry and Techniques:
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Expansion of Weaponry: Kalari practitioners in the post-Sangam
                  period continued to refine and expand their knowledge of
                  weaponry. Various weapons, including swords, staffs, and
                  daggers, became integral parts of Kalari training. The
                  emphasis on a wide array of techniques contributed to the
                  versatility of Kalari as a martial art.
                </li>
              </ul>
            </Subtitles>{" "}
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Influence of Indian Philosophies:
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Incorporation of Yoga and Ayurveda: The post-Sangam period saw
                  an increased integration of Indian philosophical concepts into
                  the Kalari and the traditional Siddha medicines. Practices
                  from Yoga, with a focus on breath control and meditation, were
                  incorporated for mental discipline. Ayurveda, the ancient
                  Indian system of medicine, influenced the holistic approach to
                  health and well-being within Kalari training.
                </li>
              </ul>
            </Subtitles>
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Spread Beyond South India into SouthEast Asia:
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Trade and Cultural Exchanges: As trade routes expanded and
                  cultural exchanges took place, Kalari began to spread beyond
                  South India. The martial art found practitioners in various
                  parts of the subcontinent, contributing to its diversity and
                  adaptation to different regional contexts.
                </li>
              </ul>
            </Subtitles>{" "}
            <Title
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              Role in Socio-Cultural Practices:
            </Title>
            <Subtitles
              color="#fff"
              font="17px"
              alignItems="start"
              textAlign="start"
            >
              <ul>
                <li>
                  Performances and Festivals: Kalari continued to be an integral
                  part of socio-cultural practices. Performances and
                  demonstrations of Kalari skills were often featured in royal
                  courts, cultural events, and festivals, contributing to its
                  popularity and recognition.
                </li>
              </ul>
            </Subtitles>
          </div>
          <Rowwrapper>
            <Columnwrapper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Subtitles
                  color="#fff"
                  font="17px"
                  alignItems="start"
                  textAlign="start"
                >
                  British Colonization Period - 1600 AD Era
                </Subtitles>
                <br />
                <Subtitles
                  color="#fff"
                  font="17px"
                  alignItems="start"
                  textAlign="start"
                >
                  The period of British colonization in India had a significant
                  impact on various aspects of Indian society, including
                  traditional martial arts like Kalari. While the British
                  colonial rule introduced changes in political, social, and
                  cultural spheres, Kalari managed to survive albeit facing
                  certain challenges and adaptations.
                </Subtitles>
                <Subtitles
                  color="#fff"
                  font="17px"
                  alignItems="start"
                  textAlign="start"
                >
                  <span>
                    1. Suppression of Indigenous Practices:
                    <ul>
                      <li>
                        British Policies: The British colonial administration,
                        particularly during the early stages of colonization,
                        was known for its efforts to suppress indigenous
                        practices, including martial arts. Traditional systems
                        like Kalari were often viewed with suspicion, as the
                        British sought to establish control and authority over
                        the subcontinent.
                      </li>
                    </ul>
                  </span>
                  <span>
                    2. Decline in Royal Patronage:
                    <ul>
                      <li>
                        Shift in Power Structures: With the decline of
                        indigenous kingdoms and the establishment of British
                        colonial rule, the traditional system of royal patronage
                        for martial arts, including Kalari, diminished. The new
                        ruling authorities did not necessarily appreciate or
                        support indigenous martial traditions.
                      </li>
                    </ul>
                  </span>
                  <span>
                    3. Cultural Appropriation:
                    <ul>
                      <li>
                        European Interest in Martial Arts: Interestingly, some
                        British officers and individuals stationed in India
                        developed an interest in Indian martial arts, including
                        Kalari. However, this interest was often from a colonial
                        perspective, with attempts to document, study, and
                        sometimes appropriate aspects of these martial arts
                        without necessarily acknowledging their cultural
                        context.
                      </li>
                    </ul>
                  </span>
                  <span>
                    4. Transformation of Kalari:
                    <ul>
                      <li>
                        Adaptation to Changing Circumstances: To survive during
                        the colonial period, Kalari underwent adaptations. Some
                        practitioners may have modified their teaching methods
                        or focused on aspects that could be presented to
                        colonial authorities as physical fitness exercises
                        rather than martial training.
                      </li>
                    </ul>
                  </span>
                  <span>
                    5. Underground Practice and Secrecy:
                    <ul>
                      <li>
                        Preservation through Secrecy: To avoid suppression and
                        maintain the continuity of Kalari, practitioners
                        sometimes resorted to practicing in secret or within
                        closed communities. This clandestine approach helped in
                        preserving the traditional techniques and knowledge
                        associated with Kalari.
                      </li>
                    </ul>
                  </span>
                  <span>
                    6. Underground Practice and Secrecy:
                    <ul>
                      <li>
                        Preservation through Secrecy: To avoid suppression and
                        maintain the continuity of Kalari, practitioners
                        sometimes resorted to practicing in secret or within
                        closed communities. This clandestine approach helped in
                        preserving the traditional techniques and knowledge
                        associated with Kalari.
                      </li>
                    </ul>
                  </span>
                  <span>
                    7. Revival in the Post-Colonial Period:
                    <ul>
                      <li>
                        Rediscovery of Heritage: The post-colonial period
                        witnessed a renewed interest in traditional Indian
                        practices, including martial arts. As India gained
                        independence, there was a conscious effort to reclaim
                        and celebrate the country's cultural heritage, leading
                        to a revival of interest in Kalari.
                      </li>
                    </ul>
                  </span>
                  <span>
                    8. Modernization and Globalization:
                    <ul>
                      <li>
                        Integration with Modern Practices: In the post-colonial
                        era, Kalari began to adapt to modern contexts. It
                        integrated elements of physical fitness, self-defense,
                        and even theatrical performances. The globalization of
                        martial arts also opened up opportunities for Kalari to
                        be recognized and practiced beyond India's borders.
                      </li>
                    </ul>
                  </span>
                  <span>
                    9. Cultural Resilience:
                    <ul>
                      <li>
                        Survival and Continuity: Despite the challenges posed by
                        British colonization, the cultural resilience of Kalari
                        and the dedication of practitioners played a crucial
                        role in its survival. The oral transmission of
                        knowledge, community-based practice, and the recognition
                        of Kalari as an integral part of India's cultural
                        heritage contributed to its continuity.
                      </li>
                    </ul>
                  </span>
                  In conclusion, the British colonization of India had a complex
                  impact on traditional martial arts like Kalari. While facing
                  suppression and a decline in royal patronage, Kalari managed
                  to adapt, survive through secrecy, and later experience a
                  revival in the post-colonial period. Today, Kalari stands as a
                  testament to the resilience of India's cultural heritage and
                  the ability of traditional practices to withstand external
                  pressures and adapt to changing circumstances.
                </Subtitles>
              </div>
            </Columnwrapper>
            <Columnwrapper>
              {" "}
              <img
                src={image_1}
                alt=""
                style={{ objectFit: "cover" }}
                width={"500px"}
                height={"300px"}
                className="mt-3"
              />
            </Columnwrapper>
          </Rowwrapper>
          <Rowwrapper>
            <Columnwrapper>
              <img
                src={image_2}
                alt=""
                style={{ objectFit: "cover" }}
                width={"500px"}
                height={"300px"}
                className="mt-3"
              />
            </Columnwrapper>
            <Columnwrapper>
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title font="20px" className="mt-3" color="#fff">
                  Modern Period - Current Era
                </Title>
                <Subtitles className="mt-3" color="#fff">
                  {" "}
                  As machines and industrialization replaced human capital
                  throughout the 20th century, martial arts from various regions
                  of the world became a solution to encourage physical
                  activities. Yoga and meditation become an activity of mental
                  health. Through British Kalari, we revive the science of Yoga,
                  Meditation and Martial arts to empower the human potential and
                  to learn the connection between body, mind and spirit.
                </Subtitles>
              </div>
            </Columnwrapper>
          </Rowwrapper>

          <Rowwrapper>
            <Columnwrapper>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title font="20px" className="mt-3" color="#fff">
                  British Kalari - Present day
                </Title>
                <Subtitles className="mt-3" color="#fff">
                  {" "}
                  Until last year, before the famous Tamil movie ‘Pattas’,
                  almost all of the Indian people used to think British Kalari
                  martial arts belonged to the Indian state of Kerala. Kerala
                  did a wonderful job in 1958 forming Kerala Kalarippayat
                  Association as one of seventeen members of the Kerala States
                  Sports Council, just 2 years after the founding of a united,
                  Malayalam-speaking Kerala State government under India. Before
                  then there were many styles of Kalari martial arts practiced
                  in the northern part of Travancore Kingdom (current state of
                  Kerala) and in the southern part of Travancore kingdom (Kumari
                  district of Tamil Nadu). Northern style of Kalari was called
                  ‘Vadakan Kalari’ that became ‘Kalaripayattu’. Southern style
                  of Kalari is called ‘Thekkan Kalari’ or ‘British Kalari’. Due
                  to the lack of cooperation among the British Kalari Aasans
                  (masters) in the southern styles, this martial art has not
                  been brought into world attention.
                </Subtitles>
              </div>
            </Columnwrapper>
            <Columnwrapper>
              <img
                src={image_3}
                alt=""
                width={"500px"}
                height={"300px"}
                className="mt-5"
                style={{ objectFit: "cover" }}
              />
            </Columnwrapper>
          </Rowwrapper>
          <Subtitles className="mt-3" color="#fff">
            The main difference between both the styles was the involvement of
            ‘Varmam’. British Kalari, the southern styles involve various forms
            of Varma attacks and it is famously called ‘Varma Adi’ or
            ‘Adimurai’. Varma is also used in healing therapy and it has been
            the vital part of Siddha Medicine. The traditional martial arts of
            British Kalari is not only involved with one’s martial defense and
            physical fitness, but also involves health and wellness, harmony
            with body and mind. British Kalari connects the relationship drawn
            from India’s unique versions of yoga practices along with the South
            Asian medicine ‘Siddha’ practices.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default History;
