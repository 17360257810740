import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export interface WrapperProsp {
  width?: string;
  height?: string;
  margin?: string;
  alignItems?: string;
  textAlign?: string;
  paddingLeft?: string;
  padding?: string;
}

export interface FontProsp {
  color?: string;
  alignItems?: string;
  fontFamily?: string;
  font?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  fontStyle?: string;
  paddingLeft?: string;
  letterSpacing?: string;
  textAlign?: string;
  hoverColor?: string;
}

export const SectionContainer = styled.section<{
  background?: string;
  padding?: string;
}>`
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${({ background }) => (background ? background : "#fff")};
  padding: ${({ padding }) => (padding ? padding : "50px 0")};
`;

export const SectionWrapper = styled.div<{
  width?: string;
  padding?: string;
  background?: string;
}>`
  margin: 0 auto;
  max-width: 1500px;
  position: relative;
  height: auto;
  width: ${({ width }) => (width ? width : "90%")};
  background: ${({ background }) => (background ? background : "transparent")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  span {
    cursor: pointer;
  }
`;

export const Rowwrapper = styled(Row)`
  display: flex;
  margin: ${({ margin }) => (margin ? margin : "20px auto")};
`;

export const Columnwrapper = styled(Col)`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
`;

export const TitleWrapper = styled.div<WrapperProsp>`
  margin: ${({ margin }) => (margin ? margin : "20px 0")};
  display: flex;
  height: ${({ height }) => (height ? height : null)};
  width: ${({ width }) => (width ? width : null)};
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
`;

export const Title = styled.h1<FontProsp>`
  font-size: ${({ font }) => (font ? font : `36px`)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  display: flex;
  flex-wrap: wrap;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : null)};
  justify-content: ${({ alignItems }) => (alignItems ? alignItems : null)};
`;

export const Subtitles = styled.p<FontProsp>`
  font-size: ${({ font }) => (font ? font : null)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : null};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : null)};
`;

export const ImageBackgroundReducer = styled.div<{ background?: string }>`
  position: absolute;
  background: ${({ background }) => (background ? background : "#000")};
  width: 100%;
  height: 100%;
  opacity: 0.8;
`;
export const TextWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  padding: 100px 0;
`;

export const ImageSection = styled.section<{ images?: any }>`
  background-image: ${({ images }) => images && `url(${images})`};
  width: 100%;
  height: 250px;
  position: relative;
  background-position: center 40%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  object-fit: contain;
`;

export const Banner = styled.div`
  background-color: #c70f2c;
  color: white;
  width: 50%;
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding: 1% 2%;
  top: 250px;
  left: 50px;
  z-index: 1111;
`;

export const PartnerImages = styled.div`
  padding: 10px;
`;

export const Partnerss = styled.img`
  object-fit: contain;
  margin: 10px;
`;

export const PreLoaderIcons = styled.img`
  animation: tilt-shaking 5s timing-function delay infinite direction fill-mode;
  @keyframes tilt-shaking {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(0eg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
