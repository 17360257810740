import styled from "styled-components";

export const TopHeader = styled.div`
  height: 40px;
  width: 100%;
  background-color: #1e1b58;
`;

export const HeaderContainer = styled.nav`
  position: fixed;
  height: 130px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 11;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 99%;
  margin: 0 auto;
  @media (max-width: 1000px) {
    justify-content: space-between;
  }
`;

export const HeaderImageWrappper = styled.div`
  width: 190px;
  height: 190px;
  display: flex;
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  vertical-align: top;
`;

export const NavOrderList = styled.ul`
  display: inline-flex;
  position: relative;
  justify-content: end;
  align-items: center;
  text-align: center;
  list-style: none;
  width: 100%;
  margin-top: 1%;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const NavList = styled.li`
  cursor: pointer;
  height: 85px;
  width: auto;
  margin: 0 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const AnchorText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    color: #c70f2c;
    transition: all 0.3s;
    font-weight: 500;
  }
`;

export const DropdownWrapper = styled.ul`
  width: auto;
  position: absolute;
  top: 95px;
  background: #fff;
  list-style: none;
  text-align: start;
  display: inline-block;
  margin: auto;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  width: 220px;
  transition: all 0.5s;
`;

export const DropdownMenuList = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    border-radius: 3px;
  }
`;

export const DropdownAnchor = styled.div`
  display: block;
  align-items: start;
  text-align: start;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #242864;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  padding: 10px;
  text-transform: uppercase;
  &:hover {
    transition: all 0.2s;
    color: #fff;
    background-color: #c70f2c;
  }
`;

//------------------------------------------mobile-nav-------------------------------------------------

export const SideMenuWrappper = styled.div`
  display: inline-flex;
  position: relative;
  justify-content: start;
  align-items: center;
  text-align: center;
  list-style: none;
  cursor: pointer;
  .menu-line {
    width: 28px;
    height: 2px;
    background: black;
    display: block;
    margin: 7px 0;
    transition: width 0.2s linear;
  }
  .menu-line-small {
    width: 22px;
    height: 2px;
    background: black;
    display: block;
    margin: 7px 0;
    transition: width 0.2s linear;
  }
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const MobileNavOrderList = styled.ul`
  display: inline-block;
  position: relative;
  justify-content: end;
  align-items: center;
  text-align: center;
  list-style: none;
  width: 100%;
`;

export const MobileNavList = styled.li`
  cursor: pointer;
  height: auto !important;
  min-height: 70px !important;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  position: relative;
  .sub-menu {
    display: block;
    position: relative;
    top: 20px;
    left: 0;
    width: 100%;
    padding: 0;
    visibility: visible;
    opacity: 1;
    z-index: auto;
    transition: 0.2s linear;
    li {
      height: 50px;
      font-weight: 500;
    }
  }
`;

export const MobileAnchorText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.2s linear;
  &:hover {
    color: #c70f2c;
    transition: all 0.3s;
    font-weight: 500;
  }
`;

export const BrandName = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  left: 50px;
  width: 40%;
  h4 {
    font-size: 18px;
    font-weight: bolder;
  }
  h6 {
    font-size: 13px;
    font-weight: bolder;
  }
`;
