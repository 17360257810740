import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import { PageWrapper } from "../../../components";

const Membership = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Membership Terms and Conditions for British Federation of Kalari
          </Title>
          <br />
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            These Membership Terms and Conditions ("Terms") govern the
            relationship between the British Federation of Kalari ("BFK," "we,"
            "us," or "our") and individuals ("Members" or "you") who wish to
            become members of BFK. By applying for membership, you agree to be
            bound by these Terms.
            <br />
            <br />
            <b> 1. Membership Eligibility:</b>
            <br />
            1.1. To be eligible for membership with BFK, you must meet the
            eligibility criteria specified by BFK, which may include age
            requirements, Kalari training experience, and compliance with BFK's
            Code of Conduct. <br />
            <br />
            1.2. Membership is open to individuals who are committed to
            promoting the principles, values, and objectives of BFK and who
            demonstrate a genuine interest in the practice and promotion of
            Kalari Martial Art. <br />
            <br />
            <b>2. Membership Application:</b>
            <br />
            2.1. To apply for membership, you must complete the membership
            application form provided by BFK and submit it along with any
            required documentation and membership fees as specified by BFK.{" "}
            <br />
            <br />
            2.2. BFK reserves the right to accept or reject membership
            applications at its sole discretion. Membership will be confirmed
            upon approval of the application by BFK. <br />
            <br />
            <b>3. Membership Benefits</b> <br />
            3.1. As a Member of BFK, you may be entitled to various benefits and
            privileges, including but not limited to access to Kalari training
            sessions, workshops, events, and resources, as well as participation
            in BFK-sanctioned competitions and activities. <br />
            <br /> 3.2. Membership benefits may vary depending on the type of
            membership and membership tier chosen by the Member, as well as any
            additional services or offerings provided by BFK. <br />
            <br />
            <b>4. Membership Fees:</b>
            <br />
            4.1. Membership fees are determined by BFK and may vary depending on
            the type of membership, membership tier, and duration of membership
            chosen by the Member. <br /> <br />
            4.2. Membership fees are payable in advance and are non-refundable
            except as expressly provided in these Terms or as required by
            applicable law <br />
            <br />
            <b>5. Membership Renewal:</b> <br /> 5.1. Membership is typically
            valid for a specified term, after which it may be subject to renewal
            upon payment of renewal fees and compliance with any renewal
            requirements specified by BFK. <br />
            <br /> 5.2. BFK reserves the right to refuse membership renewal or
            to revoke membership at its sole discretion, including but not
            limited to cases of non-payment of fees, breach of these Terms, or
            conduct deemed detrimental to the interests of BFK. <br />
            <br />
            <b>6. Code of Conduct:</b> <br /> 6.1. Members are expected to
            adhere to BFK's Code of Conduct, which sets forth standards of
            behavior, ethics, and sportsmanship expected of all Members in their
            interactions with BFK, fellow Members, instructors, officials, and
            the broader Kalari community. <br />
            <br /> 6.2. Violation of the Code of Conduct may result in
            disciplinary action, including but not limited to suspension or
            termination of membership, as determined by BFK in its sole
            discretion. <br />
            <br />
            <b>7. Termination of Membership:</b> <br /> 7.1. BFK reserves the
            right to terminate or suspend membership at any time and for any
            reason, including but not limited to non-compliance with these
            Terms, non-payment of fees, or conduct deemed detrimental to the
            interests of BFK.
            <br /> <br /> 7.2. In the event of termination or suspension of
            membership, any membership fees paid will be forfeited, and the
            Member will no longer be entitled to access membership benefits or
            participate in BFK activities.
            <br />
            <br /> <b>8. Amendments to Terms:</b> <br /> 8.1. BFK reserves the
            right to amend or modify these Terms at any time and for any reason,
            with or without notice to Members
            <br />
            <br />
            8.2. Amendments to these Terms will be effective immediately upon
            posting on the BFK website or other official BFK communication
            channels. It is the responsibility of Members to regularly review
            these Terms for updates. By applying for membership with BFK, you
            acknowledge that you have read, understood, and agree to abide by
            these Membership Terms and Conditions. If you do not agree with any
            provision of these Terms, please do not proceed with your membership
            application.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
    </PageWrapper>
  );
};

export default Membership;
