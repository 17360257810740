import styled from "styled-components";

export const AccordianWrapper = styled.div`
  background-color: lightgray;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: center;
  position: relative;
  margin: 10px 0;
  i {
    font-size: 20px;
    background-color: #c70f2c;
    color: white;
    transition: all 0.5s;
    margin-right: 20px;
  }
`;
