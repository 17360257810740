export const About = [
  {
    title: "contact",
    to: "/contact-us",
  },
  {
    title: "history",
    to: "/history",
  },
  {
    title: "who we are",
    to: "/who-we-are",
  },
  {
    title: "what we do",
    to: "/what-we-do",
  },
  {
    title: "how we do",
    to: "/how-we-do",
  },
];

const Journal = [
  {
    title: "home",
    to: "/journal-home",
  },
  {
    title: "about ijama",
    to: "/about-ijama",
  },
  {
    title: "peer review policy",
    to: "/peer-policy",
  },
  {
    title: "author guidelines",
    to: "/author-guidliness",
  },
  {
    title: "publications ethics",
    to: "/public-ethics",
  },
  {
    title: "blog",
    to: "",
  },
];

const Gov = [
  {
    title: "strategic plan",
    to: "/strategic-plane",
  },
  {
    title: "board of directors",
    to: "/board-of-directors",
  },
  {
    title: "leadership team",
    to: "/leadership",
  },
  // {
  //   title: "people plane",
  //   to: "/people-plane",
  // },
  // {
  //   title: "code of conduct",
  //   to: "/code-conduct",
  // },
  {
    title: "policies & documents",
    to: "/policies",
  },
  {
    title: "memorandum & articles",
    to: "/memoradum",
  },
  {
    title: "reports & meetings",
    to: "/reports",
  },
  {
    title: "equality & diversity",
    to: "/equality-diversity",
  },
];

const News = [
  {
    title: "partners",
    to: "/partners",
  },
  {
    title: "gallery",
    to: "/gallery",
  },
  // {
  //   title: "world champions 2023",
  //   to: "",
  // },
  // {
  //   title: "world champions 2021-22",
  //   to: "",
  // },
  // {
  //   title: "national champions 2021",
  //   to: "",
  // },
];

const Champions = [
  {
    title: "UK 2024",
    to: "/champions",
  },
];

const menu = {
  about: About,
  journal: Journal,
  news: News,
  champoins: Champions,
  gov: Gov,
};

export default menu;
