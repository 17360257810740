import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Title,
  Subtitles,
} from "../../subcomponents";
import { PageWrapper } from "../../../components";
import pdf_2 from "../../../assests/pdf/Equality and Diversity Policy.pdf";
import pdf_3 from "../../../assests/pdf/GDPR_Policy.pdf";

const GDPR = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px" width="100%">
          <Title color="black" textAlign="center" alignItems="center">
            General Data Protection Regulation
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-2"
          >
            You can find details of our policies relating to data protection in
            the documents below:
            <br />
            <br />
            <span>
              <a
                href={pdf_2}
                style={{ color: "red", textDecoration: "none" }}
                download="Equality_Diversity_Policy"
              >
                Equality and Diversity Policy
              </a>
            </span>
            <br />
            <br />
            <span>
              <a
                href={pdf_3}
                style={{ color: "red", textDecoration: "none" }}
                download="GDPR_Policy"
              >
                GDPR_Policy
              </a>
            </span>
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
    </PageWrapper>
  );
};

export default GDPR;
