import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../components";
import {
  Columnwrapper,
  ImageSection,
  Rowwrapper,
  SectionContainer,
  SectionWrapper,
  Title,
} from "../subcomponents";
import banner_image from "../../assests/banner.jpg";
import { ProgramsButton } from "./subcomponents";
import img1 from "../../assests/11031854.jpg";
import img2 from "../../assests/set_2.jpg";

const Programs = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        {" "}
        <Title color="black" textAlign="center" alignItems="center">
          PROGRAMS
        </Title>
        <br />
        <SectionWrapper background="#e4284a" padding="20px">
          <Rowwrapper>
            <Columnwrapper>
              <img src={img1} alt="" width={"auto"} height={"300px"} />
            </Columnwrapper>
            <Columnwrapper>
              <div>
                <Title
                  color="#fff"
                  font="17px"
                  textAlign="center"
                  alignItems="center"
                >
                  Online Classes
                </Title>
                <Title
                  color="#fff"
                  font="15px"
                  textAlign="center"
                  alignItems="center"
                >
                  Online classes are offered via Zoom/Virtual classroom
                </Title>
                <ul
                  style={{
                    position: "relative",
                    left: "23px",
                    color: "#FFF",
                  }}
                >
                  <li>We have 10 lesson plans offered via online.</li>
                  <li>Classes are taught by experienced Aasans (teachers)</li>
                  <li>2 classes (each 1 hour) a week</li>
                  <li>8+ classes a month</li>
                  <li>About 12 - 15 students per class</li>
                  <li>Mix of kids and adults in the class</li>
                  <li>
                    Parents and kids are encouraged to participate together
                  </li>
                  <li>Kids of age 7+ is eligible to join</li>
                </ul>
                <ProgramsButton
                  style={{
                    position: "relative",
                    left: "35px",
                  }}
                >
                  Register Here
                </ProgramsButton>
              </div>
            </Columnwrapper>
          </Rowwrapper>
        </SectionWrapper>
        <SectionWrapper background="#e4284a" padding="20px" className="mt-4">
          <Rowwrapper>
            <Columnwrapper>
              <div>
                <Title
                  color="#fff"
                  font="17px"
                  textAlign="center"
                  alignItems="center"
                >
                  In-person Classes
                </Title>
                <Title
                  color="#fff"
                  font="15px"
                  textAlign="center"
                  alignItems="center"
                >
                  In-person classes are offered at our Chennai location
                </Title>
                <ul
                  style={{
                    position: "relative",
                    left: "23px",
                    color: "#FFF",
                  }}
                >
                  <li>Classes are taught by experienced Aasans (teachers)</li>
                  <li>2 classes (each 1 hour) a week</li>
                  <li>8+ classes a month</li>
                  <li>About 12 - 15 students per class</li>
                  <li>Mix of kids and adults in the class</li>
                  <li>
                    Parents and kids are encouraged to participate together
                  </li>
                  <li>Kids of age 7+ is eligible to join</li>
                </ul>
                <Title
                  color="#fff"
                  font="15px"
                  textAlign="center"
                  alignItems="center"
                  className="mt-4"
                >
                  World Federation of Kalari Adimuari No 10, RTO Office Road,
                  (Opp. EB Office) Kovaipudur, Coimbatore, Tamil Nadu 641042,
                  India
                </Title>
                <ProgramsButton
                  style={{
                    position: "relative",
                    left: "35px",
                  }}
                  className="mt-4"
                >
                  Register Here
                </ProgramsButton>
              </div>
            </Columnwrapper>{" "}
            <Columnwrapper>
              {" "}
              <img src={img2} alt="" width={"auto"} height={"300px"} />
            </Columnwrapper>
          </Rowwrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Programs;
