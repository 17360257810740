import React, { ReactElement } from "react";
import { SectionContainer, SectionWrapper, Title } from "../../subcomponents";
import { Footer, PageWrapper } from "../../../components";

const Reports = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Report & Meetings
          </Title>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Reports;
