import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";

const PeoplePlane = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          {" "}
          <Title color="black" textAlign="center" alignItems="center">
            People Plan
          </Title>
          <br />
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            fontWeight={400}
          >
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              Our Vision
            </Title>
            <br />
            In our Strategy for Growth 2021 to 2031, published during the
            coronavirus pandemic, we set out ambitious plans to transform our
            Governing Body. The pandemic has been a catalyst and has accelerated
            some of our plans, showing us just how resilient British Kalari can
            be.
            <br />
            <br />
            Our People Plan offers an exciting opportunity to build on the
            positives of what we learned during the pandemic and will support
            our six strategic objectives, which are:
            <br />
            <br />
            <ul>
              <li>Develop and support our clubs and coaches</li>
              <li>Provide development pathways for our members</li>
              <li>Retain and increase participation</li>
              <li>Operate to the highest standard of governance</li>
              <li>Innovate and grow our organisation</li>
              <li>Support success on the world stage</li>
            </ul>
            <br />
            It will ensure we have people with the right skills, in the right
            roles, delivering the right outcomes, at the right time. This people
            plan will also take into consideration our volunteer workforce.
            <br />
            <br />
            The world of work is changing at a rapid rate, with an increasingly
            diverse working population needing and expecting enhanced
            flexibility to balance their lives at work and at home. This people
            plan aims to respond to this and deliver increased organisational
            agility for British Kalari; placing a strong emphasis on working
            together so we can be prepared to be flexible and adaptable in the
            future.
            <br />
            <br />
            We are embedding a personal development culture at British Kalari
            where clear performance targets are set for staff and volunteers in
            post. This includes access to appropriate CPD where needed to ensure
            that people have the right skills to deliver the very best outcome
            for our members.
            <br />
            <br />
            We aim to be an inclusive organisation with highly motivated staff
            and volunteers who can use their skills and abilities to make a
            difference for our clubs and members, and be the best they can be.
            <br />
            <br />
            Our People Plan has been shaped through surveys of our staff and
            volunteers and we will continue to hold consultation sessions with
            them, to ensure we are including what they think is important.
            <br />
            <br />
            Staff and volunteers have confirmed that our core values are very
            relevant, but we recognise that developing these further to
            translate into individual behaviours will help us to more deeply
            embed them into our new ways of working. <br />
            <br />
            <Title
              color="black"
              textAlign="start"
              alignItems="start"
              font="15px"
            >
              People Plan Summary
            </Title>
            This People Plan has been developed with the involvement of staff
            and volunteers to support the achievement of our Strategy for Growth
            2021-2031.
            <br />
            <br />
            By 2031 we will have embedded a strong performance management
            culture and our ways of working will reflect our core values which
            are:
            <br />
            <br />
            <ul>
              <li>Excellence</li>
              <li>Integrity</li>
              <li>Inspiration</li>
              <li>Unity</li>
            </ul>
            <br />
            <br />
            We will deliver our People Plan across several key delivery areas:
            We will deliver our People Plan across several key delivery areas:
            <br />
            <br />
            <ul>
              <li>Employer of Choice</li>
              <li>Agile Working</li>
              <li>Staff and Volunteer Engagement</li>
              <li>Wellbeing</li>
              <li>Personal Development</li>
              <li>Leadership</li>
              <li>Equality, Diversity and Inclusion (EDI)</li>
            </ul>
            <br />
            <br />
            Staff and volunteers will play a vital role in delivering the
            outcomes from this People Plan.
            <br />
            <br />
            Our staff will continually review and improve the services they
            deliver across British Kalari; looking to streamline processes, use
            technology and provide approachable, proactive, pragmatic and
            business-focused support and guidance to empower our volunteers.
            <br />
            <br />
            You can download the full British Kalari People Plan{" "}
            <span style={{ color: "red" }}>here</span>.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default PeoplePlane;
