import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import { Footer, PageWrapper } from "../../../components";
import user_images from "../../../assests/board/board.png";
import BoardCard from "../board/helper";

const LeadersDetails = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Join Our Leadership Team
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            Are you passionate about Kalari and interested in contributing to
            the leadership of the British Federation of Kalari? Contact us to
            learn more about opportunities to get involved and make a difference
            in promoting this ancient martial art form in the UK. <br />
            <br /> Together, let's lead the way in preserving and promoting the
            timeless tradition of Kalari in the United Kingdom!
          </Subtitles>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <BoardCard
              name="Murugupandian Pandian"
              role="Chairperson / President"
              image={user_images}
              type="Executive Director"
              subtitle="Mr. Murugupandian Pandian brings a wealth of experience and dedication to his role as President of the British Federation of Kalari. With a deep-rooted passion for preserving and promoting traditional Indian martial arts, Mr. Pandian is committed to fostering a thriving community of Kalari practitioners in the UK. His passion for Kalari and his dedication to community engagement drive initiatives aimed at expanding the reach and impact of Kalari across diverse communities in the UK. His visionary leadership and strategic guidance steer the federation towards its mission of empowering individuals through the ancient art of Kalari."
            />{" "}
            <BoardCard
              name="Dilip Pushpa Rajan"
              role="Secretary"
              image={user_images}
              type="Executive Director"
              subtitle="As Vice President, Mr. Dilip Pushpa Rajan plays a pivotal role in shaping the strategic direction of the British Federation of Kalari. He brings valuable insights and innovative ideas to the federation. Mr. Pushpa Rajan serves as the Secretary of the British Federation of Kalari, overseeing administrative operations and ensuring the smooth functioning of the federation. With his meticulous attention to detail and organizational acumen, Mr. Pushpa Rajan plays a key role in coordinating events, managing communications, and facilitating collaboration among federation members. His dedication to efficiency and transparency ensures that the federation operates with integrity and professionalism"
            />{" "}
            <BoardCard
              name="Rajarajeswari Lakshmanan"
              role="Treasurer"
              image={user_images}
              type="Executive Director"
              subtitle="Mrs. Rajarajeswari Lakshmanan serves as the Treasurer of the British Federation of Kalari, responsible for financial management and accountability. With her background in finance and her commitment to fiscal responsibility, Mrs. Lakshmanan ensures that the federation's resources are managed judiciously and transparently. Her expertise in budgeting and financial planning enables the federation to pursue its mission effectively while maintaining financial stability and sustainability."
            />{" "}
            <BoardCard
              name="TBC–Vacancy"
              role=""
              image={user_images}
              type=""
              subtitle="VP–Marketing & Commercial"
            />
            <BoardCard
              name="TBC–Vacancy"
              role=""
              image={user_images}
              type=""
              subtitle="VP–Legal"
            />
            <BoardCard
              name="TBC–Vacancy"
              role=""
              image={user_images}
              type=""
              subtitle="VP–Compliance & Govt Affairs"
            />
            <BoardCard
              name="TBC–Vacancy"
              role=""
              image={user_images}
              type=""
              subtitle="VP–Research & Development"
            />
          </div>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default LeadersDetails;
