import React, { ReactElement, ReactNode, useState } from "react";
import { AccordianWrapper } from "./subcomponent";
import { Title } from "../../pages/subcomponents";

interface Types {
  title?: string;
  subTitle?: ReactNode | string;
}

const Accordian2 = ({ subTitle, title }: Types): ReactElement => {
  const [open, setOpen] = useState(false);
  return (
    <AccordianWrapper>
      <div style={{ display: "flex", position: "relative", top: "3px" }}>
        <div>
          {" "}
          <i
            className={!open ? "bx bx-plus" : "bx bx-minus"}
            onClick={() => {
              setOpen(!open);
            }}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <Title font="15px">{title}</Title>
      </div>
      {open && (
        <div style={{ position: "relative" }}>
          <AccordianWrapper>{subTitle}</AccordianWrapper>
        </div>
      )}
    </AccordianWrapper>
  );
};

export default Accordian2;
