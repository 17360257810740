import {
  StyledButton2,
  CardWrapper,
  Image,
  NewsCardWrapper,
} from "./subcomponents";
import { Title, TitleWrapper } from "../subcomponents";
import { useEffect } from "react";
import Aos from "aos";

interface NewsProsp {
  image?: string;
  title: string;
  date: any;
}

export const NewsCard = ({ image, title, date }: NewsProsp) => {
  return (
    <NewsCardWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          objectFit: "contain",
        }}
      >
        <Image src={image} alt="image" />
      </div>
      <TitleWrapper
        alignItems="start"
        textAlign="start"
        margin="0"
        className="mt-4"
      >
        <Title font=".9rem">{title}</Title>
        {/* <Subtitles className="mt-3" font=".8rem">
          {moment(date).format("DD-MMMM")}
        </Subtitles> */}
      </TitleWrapper>
    </NewsCardWrapper>
  );
};

interface CardProsp {
  image: string;
  buttonName: string;
}

export const Cards = ({ image, buttonName }: CardProsp) => {
  useEffect(() => {
    Aos.init({
      duration: 900,
      once: false,
    });
  }, []);
  return (
    <CardWrapper>
      <Image src={image} alt="Image" />
      <StyledButton2 data-aos="flip-up">{buttonName}</StyledButton2>
    </CardWrapper>
  );
};
