import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
  TitleWrapper,
} from "../../subcomponents";

const HowWeDo = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper background="#C70F2C" padding="20px">
          <TitleWrapper>
            <Title color="#fff">HOW WE DO...</Title>

            <Subtitles
              font="15px"
              className="mt-3"
              color="#fff"
              textAlign="start"
              alignItems="start"
            >
              We teach British Kalari via both in-person and online classes.
              Online classes are taught via zoom virtual classrooms. In-person
              classes are offered in our Chennai, Tamil Nadu, India location at
              this time. Please contact us for more details. You can find more
              details on both classes in our Programs section.
            </Subtitles>
          </TitleWrapper>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default HowWeDo;
