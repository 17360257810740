import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Title,
  Subtitles,
} from "../../subcomponents";
import { PageWrapper } from "../../../components";

const Health = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Title color="black" textAlign="center" alignItems="center">
            Health and Safety Policy
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-2"
          >
            At British Kalari, the health and safety of our members,
            instructors, staff, and visitors are of paramount importance. We are
            committed to providing a safe and healthy environment for the
            practice and promotion of Kalari Martial Art. This policy outlines
            our approach to managing health and safety within our organization.
            <br />
            <br />
            <b>Responsibilities</b>
            <br />
            <br />
            <b>Management Responsibility:</b>
            <br />
            Management is responsible for providing leadership and direction in
            health and safety matters. <br /> They will ensure that adequate
            resources are allocated to maintain a safe environment and that all
            legal requirements are met.
            <br />
            <br />
            <b>Instructor Responsibility:</b>
            <br />
            Instructors are responsible for ensuring that training sessions are
            conducted safely and that participants are instructed on proper
            techniques and safety precautions. <br /> They will conduct risk
            assessments and implement control measures to minimize hazards
            during training sessions.
            <br />
            <br />
            <b>Participant Responsibility:</b>
            <br />
            Participants are responsible for following safety instructions
            provided by instructors, using appropriate safety equipment, and
            reporting any hazards or concerns to the instructor immediately.
            <br />
            <br />
            <b>Risk Assessment and Control Measures:</b>
            <br />
            <br />
            <b>Facility Safety:</b>
            <br />
            Regular inspections will be conducted to identify and address
            hazards within the training facility, including slippery floors,
            uneven surfaces, and obstructions. <br /> Emergency exits will be
            clearly marked and kept unobstructed at all times.
            <br />
            <br />
            <b>Equipment Safety:</b>
            <br />
            All training equipment, including weapons and protective gear, will
            be regularly inspected for damage and replaced as necessary <br />
            Participants will be instructed on the safe use and handling of
            equipment to minimize the risk of injury.
            <br />
            <br />
            <b>First Aid and Emergency Procedures:</b>
            <br />
            First aid kits will be readily available within the training
            facility, and designated individuals will be trained in first aid
            procedures. <br /> Emergency procedures, including evacuation plans
            and contact information for emergency services, will be prominently
            displayed and communicated to all participants.
            <br />
            <br />
            <b>Health and Hygiene:</b>
            <br />
            <br />
            <b>Personal Hygiene:</b>
            <br />
            Participants are expected to maintain good personal hygiene,
            including regular handwashing and proper grooming, to minimize the
            risk of infection and illness.
            <br />
            <br />
            <b>Injury Prevention:</b>
            <br />
            Warm-up exercises and stretching routines will be incorporated into
            training sessions to reduce the risk of muscle strains and injuries.{" "}
            <br />
            Participants will be encouraged to listen to their bodies and avoid
            pushing themselves beyond their physical limits to prevent
            overexertion and injury.
            <br />
            <br />
            <b>Training and Supervision:</b>
            <br />
            <br />
            <b>Qualified Instruction:</b>
            <br />
            Instructors will hold relevant qualifications and certifications in
            Kalari Martial Art and will receive ongoing training in health and
            safety practices. <br /> They will ensure that training sessions are
            conducted in a manner that minimizes the risk of injury while
            promoting skill development and physical fitness.
            <br />
            <br />
            <b>Supervision:</b>
            <br />
            Participants will be supervised at all times during training
            sessions, especially when practicing techniques that involve
            physical contact or the use of weapons. <br /> Instructors will
            monitor participants for signs of fatigue or distress and intervene
            as necessary to prevent accidents or injuries.
            <br />
            <br />
            <b>Communication and Review:</b>
            <br />
            <br />
            <b>Communication:</b>
            <br />
            This Health and Safety Policy will be communicated to all members,
            instructors, staff, and visitors of British Kalari. <br /> Regular
            updates and reminders will be provided to ensure that everyone
            remains aware of their responsibilities and the latest safety
            protocols.
            <br />
            <br />
            <b>Review and Improvement:</b>
            <br />
            This policy will be regularly reviewed and updated to reflect
            changes in legislation, best practices, and organizational
            requirements. <br /> Feedback from participants and incidents or
            near misses will be used to identify areas for improvement and
            implement corrective actions. <br /> At British Kalari, we are
            committed to fostering a culture of safety and well-being within our
            organization. By adhering to the principles outlined in this policy
            and working together to promote a safe training environment, we can
            ensure that everyone can enjoy the benefits of Kalari Martial Art in
            a manner that prioritizes health and safety.
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
    </PageWrapper>
  );
};

export default Health;
