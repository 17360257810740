import React, { ReactElement } from "react";
import {
  SectionContainer,
  SectionWrapper,
  Title,
  Subtitles,
} from "../../subcomponents";
import { Footer, PageWrapper } from "../../../components";
import Accordian2 from "../../../components/Accordian2";
import Health from "../health";
import GDPR from "../gdpr";
import CodeofConduct from "../codeofconduct";
import AntiDoping from "./antidoping";
import Membership from "./membership";

const Policies = (): ReactElement => {
  return (
    <PageWrapper>
      <SectionContainer>
        <SectionWrapper padding="20px">
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mb-3"
          >
            Policies & Documents of British Kalari. Here you will find the
            <br />
            <br />
            policies of British Kalari along with key documents.
          </Subtitles>
          <Title color="black" textAlign="center" alignItems="center">
            Policies & Documents
          </Title>
          <Subtitles
            textAlign="start"
            color="black"
            alignItems="start"
            font="14px"
            className="mt-3"
          >
            <Accordian2 title="Anti-Doping" subTitle={<AntiDoping />} />
            <Accordian2 title="Code of Conduct" subTitle={<CodeofConduct />} />
            <Accordian2 title="Complaints" />
            <Accordian2 title="Data Protections and GDPR" subTitle={<GDPR />} />
            <Accordian2 title="Events" />
            <Accordian2 title="Health & Safety" subTitle={<Health />} />
            <Accordian2 title="General Informations" />
            <Accordian2 title="Memebership Guidance" />
            <Accordian2
              title="Memebership Terms and Conditions"
              subTitle={<Membership />}
            />
            <Accordian2 title="Safeguarding" />
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default Policies;
