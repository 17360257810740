import React, { ReactElement } from "react";
import { Footer, PageWrapper } from "../../../components";
import {
  ImageSection,
  SectionContainer,
  SectionWrapper,
  Subtitles,
  Title,
} from "../../subcomponents";
import banner_image from "../../../assests/banner.jpg";

const JournalHome = (): ReactElement => {
  return (
    <PageWrapper>
      <ImageSection images={banner_image}>
        {/* <Banner>HISTORY</Banner> */}
      </ImageSection>

      <SectionContainer>
        <SectionWrapper background="#e4284a" padding="20px">
          <Title color="#fff" textAlign="center" alignItems="center">
            HOME
          </Title>

          <Subtitles
            color="#fff"
            font="15px"
            className="mt-3"
            textAlign="start"
            alignItems="start"
          >
            <b>International Journal of Ancient Martial Arts</b> (IJAMA) is an
            international, open-access Biannual online Refereed journal that
            accepts research works from scholars, academicians, professors,
            doctorates, lecturers and corporate in their respective expertise of
            studies. The aim of IJAMA is to publish peer reviewed research and
            review articles fastly without delay. This journal's goal is to
            publish unique research in its subject in order to advance our
            understanding of historical martial arts across all academic fields.
            IJAMA seeks to publish more useful and purely scholarly articles.
            This means that it includes the entire spectrum of research that is
            applied to different sciences to fulfil future demands. All
            submitted articles must present original, never-before-published
            (theoretical or experimental) research and findings. Peer reviews
            will be conducted on each submission under the IJAMA's authority.
            Plagiarism is also checked on all entries. ​
            <br />
            <br />
            <Subtitles
              textAlign="center"
              alignItems="center"
              color="#fff"
              fontWeight={600}
            >
              DISCLAIMER
            </Subtitles>
            The information and opinions presented in the journal reflect the
            views of the authors and not of the journal or its editorial board
            or the publisher. Publication does not constitute endorsement by the
            journal. Neither the journal nor anyone else involved in creating,
            producing or delivering IJAMA or the materials contained therein,
            assumes any liability or responsibility for the accuracy,
            completeness, or usefulness of any information provided in IJAMA,
            nor shall they be liable for any direct, indirect, incidental,
            special, consequential or punitive damages arising out of the use of
            IJAMA. IJAMA or any other party involved in the preparation of the
            material contained in IJAMA represents or warrants that the
            information contained herein is in every respect accurate or
            complete, and they are not responsible for any errors or omissions
            or for the results obtained from the use of such material. Readers
            are encouraged to confirm the information contained herein with
            other sources.
            <br />
            <br />
            <Subtitles
              textAlign="center"
              alignItems="center"
              color="#fff"
              fontWeight={600}
            >
              PUBLICATION CHARGES
            </Subtitles>
            The journal has nominal publication charges. Publication charges
            need to be paid only after the paper is accepted for publication.
            Publication charges will be the cost towards processing, maintenance
            of paper in secured data storage system, databases; to meet various
            overhead expenses like employees charges etc. Published papers
            appear electronically and are freely available from our website.
          </Subtitles>
          <Subtitles
            color="#fff"
            font="15px"
            textAlign="start"
            alignItems="start"
          >
            <ul>
              <li>For Indian Author: Rs 1000</li>
              <li>For Foreign Author: 20 US Dollars</li>
            </ul>
          </Subtitles>
        </SectionWrapper>
      </SectionContainer>
      <Footer />
    </PageWrapper>
  );
};

export default JournalHome;
